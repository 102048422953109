import React from "react";
import { ReactComponent as FordLoader } from '../../assets/images/ford-loader.svg';
import './CFAdminLoader.scss'; 

type LoaderProps = {
    message: string;
  };

const Loader = (props: LoaderProps) => {
  return (

        <div className="loading-screen">
        <div className="container-div">
            <FordLoader height="50" />
            <div>{props.message} ... </div>
        </div>
      </div>
  );
};

export default Loader;