import CFAdminDataSelectTable from "../../components/CFAdminDataTable/CFAdminDataSelectTable";
import React, {useState} from "react";

function CFAdminSystemSuggestedVehicles ({columnValues, dataValues, handleSelectedVehicles} : any) {

    const [selectedRows, setSelectedRows] = useState<any>([]);

    const clickDiscardedButton = () => {
        handleSelectedVehicles(selectedRows);
    }

    return (
        <>
            <div className="ext1">
                There are <b>{dataValues.length}</b> new vehicles. Please determine whether a new vehicle configurations need to be
                generated.
            </div>
            <div className="ext2">
                If a new configuration is not needed, remove the vehicle from the list by selecting its checkbox and
                clicking "Discard Checked."
            </div>
            <p>Selected Rows: {Object.keys(selectedRows).length}</p>
            <CFAdminDataSelectTable
                columns={columnValues}
                data={dataValues}
                textModal={''}
                showFilter={false}
                showAdd={false}
                showPagination={false}
                showSelect={true}
                onRowSelect={(rows: any) => setSelectedRows(rows)}
                pageSize={1000}
            />
            <button data-testid="submit-discard" className="fvp-button button-right-align"
                    disabled={selectedRows.length === 0}
                    onClick={clickDiscardedButton}>
                Discard Checked <i className="fvp-right-chevron"/>
            </button>
        </>
    )
}

export default CFAdminSystemSuggestedVehicles;
