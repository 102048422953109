import React, {useEffect} from "react";
import CFAdminDataTable from "../../components/CFAdminDataTable";
import ViewIcon from "../../assets/images/view-icon.svg";

function CFAdminIntervals ({vehicleDetails, showSchedule,  dataValues} : any) {

    const handleIntervalClick = async (event: any) => {
        vehicleDetails.scheduleCode = event.row.original.scheduleCode
        showSchedule();
    };

    const columnValues = [
        {
            Header: "Id",
            accessor: 'scheduleCode'
        },
        {
            Header: "Description",
            accessor: 'scheduleDescription'
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                return (
                    <div>
                            <button data-testid={`cell-edit-button${props.row.index}`} className="edit-button"
                                    onClick={() => handleIntervalClick(props)} title="Edit">
                                <img src={ViewIcon} alt="Xcelvations Logo" height="10"/>
                            </button>
                    </div>
                )
            },
        }
    ];

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <CFAdminDataTable
                columns={columnValues}
                data={dataValues}
                    showAdd={false}
                    showPagination={false}
                    pageSize={1000}
                />
            </>
    )
}
export default CFAdminIntervals;