import './CFAdminHome.scss';

function CFAdminHome() {
    return (
        <>
        <div className="fvp-home-background">
          <p className="fvp-home-text-top">Welcome to the FCSD Portal Website - this site provides access to all 
            administrative and business analysis information for the 
            FCSD B2C Application Portfolio</p>
        </div>
          
        </>
      );
  }
  
  export default CFAdminHome;