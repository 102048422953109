import React, { useState } from "react";
import Tab from "./Tab";
import './CFAdminTabs.scss'

const CFAdminTabs = ({ tabs } : any) => {
    const [activeTab, setActiveTab] = useState(tabs[0].label);
    const [contentTab, setContentTab] = useState(tabs[0].content);

    const handleTabClick = (index : any, content: any) => {

        setActiveTab(index);
        setContentTab(content);
    };

    return (
        <div className="tabs-container">
            <div className="tabs">
                {tabs.map((tab : any, index : any) => (
                    <Tab
                        key={tab.label}
                        label={tab.label}
                        onClick={() =>
                            handleTabClick(tab.label,tab.content)
                        }
                        isActive={tab.label === activeTab}
                    />
                ))}
            </div>
            <div className="tab-content">
                {contentTab}
            </div>
        </div>
    );
};

export default CFAdminTabs;
