import React, {useEffect, useState} from 'react';
import Select from "react-select";

function CFAdminBundle({id, desc, activeStep, bundleOptions, handleBundleOptions,bulkBundleConfigs}: any) {

    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

    useEffect(() => {
        // Set the selected options when the component mounts or activeStep changes
        if (activeStep === 0 && id === 'bundle') {
            setSelectedOptions(bulkBundleConfigs.bundleIds);
        }else if (activeStep === 0 && id === 'operation') {
            setSelectedOptions(bulkBundleConfigs.operationIds);
        } else if (activeStep === 1) {
            setSelectedOptions(bulkBundleConfigs.drivingConditionIds);
        } else if (activeStep === 2) {
            setSelectedOptions(bulkBundleConfigs.intervalIds);
        }
    }, [activeStep, bulkBundleConfigs, id]);

    const onChangeBundle = async (selectedOptions: any) => {

        handleBundleOptions(id, selectedOptions);
        setSelectedOptions(selectedOptions);
    };

    return (
        <div className="container">
            <div className="fvp-form__subgroup">
                {activeStep === 0 && id === 'bundle' && (
                    <div className="ext1-11">
                        <b> Please select the bundles you want to recommend.</b>
                    </div>
                )}
                {activeStep === 0 && id === 'operation' && (
                    <div className="ext1-11">
                        <b> Please select the operations you want to recommend.</b>
                    </div>
                )}
                {activeStep === 1 && (
                    <div className="ext1-11">
                        <b> Please select the Driving Conditions you want to recommend.</b>
                    </div>
                )}
                {activeStep === 2 && (
                    <div className="ext1-11">
                        <b> Please select the Intervals you want to recommend.</b>
                    </div>
                )}
                <div style={{width: "800px"}} data-testid={id + '-test'}>
                    <Select
                        isMulti
                        name="bundle"
                        options={bundleOptions}
                        value={selectedOptions}
                        onChange={onChangeBundle}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default CFAdminBundle;