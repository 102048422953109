import './CFAdminModels.scss';
import {Formik} from 'formik'
import * as Yup from 'yup';
import {FVPHiddenInput, FVPSelect, FVPTextInput} from '../../components/formField';

import ModelService from '../../services/ModelService';
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import React, {useState} from "react";

// Creating schema
const schema = Yup.object().shape({
  description: Yup.string()
      .required("Description is a required field")
      .min(1, "Description must be at least 3 characters")
      .max(100,"Description must be at least 10 characters"),
  makeId: Yup.number().required()
      .min(1,"Make is a required field")

  });

type CFAdminMakesProps = {
    id?: number;
    description: string,
    makeId?: number,
    makeDescription: string;
    country: string;
    language: string;
    usedFlag: boolean;
    toggleModal: any;
    refreshData: any;
    successMessage: any;
    errorMessage: any;
    isDelete:boolean;
    makes:any;
};

function CFAdminModelsForm({
  id,
  description,
  makeId,
  makeDescription,
  country,
  toggleModal,
  refreshData,
  successMessage,
  errorMessage,
  isDelete,
  makes
}: CFAdminMakesProps) {
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const saveOperation = (values: any) => {
      let data = {
      "makeId": +values.makeId,
      "description": values.description
    };

    ModelService.create(data)
      .then(() => {
        successMessage(`Model: ${values.description} added successfully.`);
        refreshData();
        toggleModal();
      })
      .catch(e => {
        errorMessage(e.response.data.errorMessage);
      });
  };

  const updateOperation = (values: any) => {
      let data = {
      "makeId": +values.makeId,
      "description": values.description
    };
  
    ModelService.update(+values.id, data)
      .then(() => {
        successMessage(`Model: ${values.description} updated successfully.`);
        refreshData();
        toggleModal();
      })
      .catch(e => {
        errorMessage("Error while updating Model!");
          console.error(e);
      });
  };

  const deleteOperation = () => {
      if (id) {
          ModelService.delete(id)
              .then(() => {
                  successMessage(`Model: ${description} deleted successfully.`);
                  refreshData();
                  toggleModal();
              })
              .catch(e => {
                  errorMessage("Error while deleting Model!");
                  console.log(e);
              });
      } else {
          errorMessage("Error while deleting Model!");
      }
  };
  
    return (
        <>
        <Formik
        validationSchema={schema}
        initialValues={{ id:id, description: description, makeId: makeId, makeDescription: makeDescription,
          country: country }}
        onSubmit={(values) => {
            values.makeDescription = makes.find((make: { code: number; })=> make.code === Number(values.makeId))?.description;
          if(isDelete){
              setIsDeleteConfirmOpen(true);
          }else{
            if(id !== undefined && id > 0){
              updateOperation(values);
            }else{
              saveOperation(values);
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          dirty
        }) => (
          <div className="fvp-form">
            <div >
              {/* Passing handleSubmit parameter tohtml form onSubmit property */}
              <form data-testid="SubmitForm" noValidate onSubmit={handleSubmit}>
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                <div className="modal-body">
                    <div className="fvp-form__group">
                    <FVPHiddenInput name="modelId" />
                    <FVPTextInput label="Description:" name="description" required readOnly={isDelete} disabled={isDelete}/>
                    <FVPSelect
                      label="Make"
                      name="makeId"
                      options={makes}
                      required
                      disabled={isDelete}
                    />
                  </div>
                </div>
                <div className="modal-footer button-frame">
                  {/*
                  consider a special class for a collection of buttons; their
                  wrapping should probably be done in a special way (submit to the
                  right when laid out in a row, on top when stacked vertically.)
                  */}
                  <button
                    data-testid="Cancel"
                    className="fvp-button--outline"
                    onClick={toggleModal}
                  >
                    <i className="fvp-left-chevron" />
                    Cancel
                  </button>
                  {isDelete? 
                  <button
                    data-testid="Delete"
                    className="fvp-button-danger--outline"
                    type="submit"
                  >
                    Delete
                    <i className="fvp-right-chevron" />
                  </button> : <button
                    data-testid="Save"
                    className="fvp-button"
                    type="submit"
                    disabled={!(dirty && isValid)}
                  >
                    Save
                    <i className="fvp-right-chevron" />
                  </button>}
                </div>
            </form>
            </div >
            
          </div>
        )}
      </Formik>
            <CFAdminConfirmDialog
                isOpen={isDeleteConfirmOpen}
                onClose={()=>setIsDeleteConfirmOpen(false)}
                onConfirm={deleteOperation}
                title={"Delete Model: " + description}
                text="Are you sure you want to delete this Model?"
            />
      </>
    );
  }

export default CFAdminModelsForm;