import FordImage from '../../assets/images/Ford_Oval.svg';
import './CFAdminFooter.scss';


function CFAdminFooter(_props: any) {
    return (
      <footer className="fvp-footer">
        <div className='footer-image'>
            <img src={FordImage} alt="Xcelvations Logo" height="20" />
            <h3 className='footer-title'>Copyright © 2024 Ford Motor Company, All Rights Reserved.</h3>
        </div>
      </footer>
    );
  }
  
  
  export default CFAdminFooter;