import React, {useEffect} from "react";
import CFAdminDataTable from "../../components/CFAdminDataTable";

function CFAdminDrivingConditions ({vehicleDetails, switchToVehConfig,  dataValues} : any) {

    const handleDcClick = async (event: any) => {
        vehicleDetails.drivingConditionId = event.row.original.drivingConditionId
        switchToVehConfig();
    };

    const columnValues = [
        {
            Header: "Id",
            accessor: 'drivingConditionId'
        },
        {
            Header: "Description",
            accessor: 'drivingConditionDescription'
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                return (
                    <div>
                        <button data-testid={`dc-step-next${props.row.index}`}
                                type="button"
                                onClick={() => handleDcClick(props)}>
                            Select
                        </button>
                    </div>
                )
            },
        }
    ];

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
            <>
                <CFAdminDataTable
                    columns={columnValues}
                    data={dataValues}
                    showAdd={false}
                    showPagination={false}
                    pageSize={1000}
                />
            </>
    )
}
export default CFAdminDrivingConditions;