import './CFAdminAccess.scss'

function CFAdminAccess() {
  const redirectToAccessMgnt = () => {
    window.location.replace("https://www.accessmgmt.ford.com/CspsWeb/cspsHomeBegin.do");
} 

  return (
    <>
    <div className="fvp-access-background">
        <p className="fvp-access-text-top">You need access to FCSD Portal Website - FCSD B2C Application Portfolio</p>
        <p className="fvp-access-text-middle"> Ask for access, or switch to an account with access</p>
        <p className="fvp-access-text">Thank You!</p>
        <div className='fvp-access-bottom'>
            <button className="fvp-button" onClick={redirectToAccessMgnt} >Request Access</button>
        </div>
    </div>
    </>
  );
  }
  
  export default CFAdminAccess;