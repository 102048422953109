import React, {useEffect, useState} from "react";
import CFAdminDataTable from "../../components/CFAdminDataTable";
import {DeleteButton} from "../../components/CFAdminDataTable/ActionsButtonComponents";
import VehicleConfigService from "../../services/VehicleConfigService";
import {VehicleAttributeType} from "./types";
import DeleteIcon from "../../assets/images/delete.svg";
import AddIcon from "../../assets/images/add-icon.svg";
import ViewIcon from "../../assets/images/view-icon.svg";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";

function CFAdminVehicleConfigDetails ({vehicleDetails, switchToCreate, closeModal, successMessage, errorMessage} : any) {

    const [columnValues, setColumnValues] = useState<any>([]);
    const [dataValues, setDataValues] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [modelYearId, setModelYearId] = useState<number>(0);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isDeleteVehicleConfirmOpen, setIsDeleteVehicleConfirmOpen] = useState(false);
    const [isDeleteAttributeConfirmOpen, setIsDeleteAttributeConfirmOpen] = useState(false);
    const [deleteVehicleId, setDeleteVehicleId] = useState<number>(0);
    const [attributeId, setAttributeId] = useState<number>(0);

    const renderDeleteButton = (props: any, handleDelete: Function) => {
        return <DeleteButton {...props} handleDelete={handleDelete} />
    }

    const handleEdit = (event: any) => {
        switchToCreate();
    }

    const handleAttributeDelete = (attributeId: any) => {
        setAttributeId(attributeId);
        setIsDeleteAttributeConfirmOpen(true);
    }

    const handleDelete = async (event: any) => {
        setDeleteVehicleId(event.originalRow.vehicleId);
        setIsDeleteVehicleConfirmOpen(true);
    }

    const vehicleDeleteEvent = async () => {
        setIsDeleteConfirmOpen(true);
    }

    const invokeVehicleDelete = () => {
        if (modelYearId !==0)
        {
            VehicleConfigService.deleteModelYear(modelYearId)
                .then(() => {
                    successMessage(`Model: ${vehicleDetails.model} and Year: ${vehicleDetails.year} deleted successfully.`);
                    setIsDeleteConfirmOpen(false);
                    closeModal();
                })
                .catch(e => {
                    errorMessage("Error while deleting Model!");
                });
        } else {
            errorMessage("Invalid Model");
        }
    }

    const invokeVehicleConfigDelete = () => {
        if (modelYearId !== 0 && deleteVehicleId !== 0)
        {
            VehicleConfigService.deleteVehicle(deleteVehicleId)
                .then(() => {
                    successMessage(`Vehicle deleted successfully for Model: ${vehicleDetails.model} and Year: ${vehicleDetails.year}.`);
                    setIsDeleteVehicleConfirmOpen(false);
                    getVehicleConfigurations();
                })
                .catch(e => {
                    errorMessage("Error while deleting Model!");
                });
        } else {
            errorMessage("Invalid Model");
        }
    }

    const invokeVehicleAttributeDelete = () => {
        if (modelYearId !==0 && attributeId !==0)
        {
            VehicleConfigService.deleteVehicleAttribute(attributeId, modelYearId)
                .then(() => {
                    successMessage(`Model: ${vehicleDetails.model} and Year: ${vehicleDetails.year} deleted successfully.`);
                    setIsDeleteAttributeConfirmOpen(false);
                    getVehicleConfigurations();
                })
                .catch(e => {
                    errorMessage("Error while deleting Model!");
                    console.log(e);
                });

        } else {
            errorMessage("Invalid Model");
        }
    }

    const handleView = (event: any) => {
        alert("view");
    }

    const vehicleData = [
        {
            Year: vehicleDetails?.year,
            Make: vehicleDetails?.make,
            Model: vehicleDetails?.model === '' ? vehicleDetails?.modelDesc : vehicleDetails?.model
        },
    ];

    function getVehicleConfigurations(){
        const modelDesc = vehicleDetails?.model === '' ? vehicleDetails?.modelDesc : vehicleDetails?.model;

        VehicleConfigService.getVehicleConfigurationDetails(vehicleDetails?.make, modelDesc, vehicleDetails?.year)
            .then(response => {

                const responseModel = response.data?.vehicles;
                if (responseModel) {

                    let columns : any = [];
                    let data : any = [];
                    let count: number = 0;
                    let isColAdded : boolean = false;
                    responseModel.forEach((parentElement: any) => {
                        count = count+1;
                        if(!isColAdded) {
                            columns.push({Header: '', accessor:'id', disableSortBy: true});
                            parentElement.vehicleAttributes.forEach((childElement: VehicleAttributeType) => {
                                columns.push({ //Header: childElement.attributeDescription ,
                                                Header: () => (
                                                    <div className="flex">
                                                        <div className="inline-flex">{childElement.attributeDescription}
                                                           &nbsp; <img data-testid={childElement.attributeId} onClick={() => handleAttributeDelete(childElement.attributeId)}
                                                                       src={DeleteIcon} alt="Xcelvations Logo" height="15"/>
                                                        </div>
                                                    </div>
                                                ),
                                              disableSortBy: true,
                                              accessor: 'attributeValueDescription'+childElement.attributeId})
                            });
                            columns.push({Header: "Actions", disableSortBy: true, accessor:'', Cell: (props: any) => {
                                    return (
                                        <div className="cell-buttons">
                                            {renderDeleteButton(props, handleDelete)}
                                        </div>
                                    )},});
                            isColAdded = true;
                        }
                        data.push(createJson(count, parentElement.vehicleId, parentElement.vehicleAttributes));
                        setModelYearId(parentElement.yearModelId);
                    });
                    setColumnValues(columns);
                    setDataValues(data);
                    setTotalRecords(count);
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    function createJson(count:number, vehicleId: number, childElements: any)
    {
        let jsonData : any = {};
        childElements.forEach((childElement: VehicleAttributeType) => {
            let columnName = 'attributeValueDescription'+childElement.attributeId;
            jsonData[columnName] = childElement.attributeValueDescription;
        });
        jsonData['id'] = 'Configuration ' + count;
        jsonData['vehicleId'] = vehicleId;

        return jsonData;
    }

    const renderTable = (data: any) => {
        const headings = ["Year", "Make", "Model"];
        return (
            <table className="inner-table">
                <thead>
                <tr>
                    {headings.map((heading) => (
                        <th style={{background: 'aliceblue', color:'black'}} key={heading}>
                            {heading} </th>
                    ))}
                    <th style={{background: 'aliceblue', color:'black'}}>
                        <div data-testid="modal-delete" onClick={vehicleDeleteEvent}>
                            Delete Vehicle &nbsp; <img src={DeleteIcon} alt="Xcelvations Logo" height="15"/>
                        </div></th>
                </tr>
                </thead>
                <tbody>
                {data.map((row : any, rowIndex:any) => (
                    <tr key={rowIndex}>
                        { headings.map((heading) => (
                            ( heading === "Model" ?
                            <td onClick={handleView} key={row[heading]}>{row[heading]}
                                &nbsp; <img src={ViewIcon} alt="Xcelvations Logo" height="15"/></td>:
                                <td key={row[heading]}>{row[heading]}</td> )
                        ))}
                        <td onClick={handleEdit}>Generate New Configurations &nbsp;
                            <img src={AddIcon} alt="Xcelvations Logo" height="15"/> </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    useEffect(() => {
        getVehicleConfigurations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="ext5">
                Search Results
            </div>
            <div className="ext2">
                * {totalRecords} vehicle configurations were found for the vehicle listed below
            </div>
            <div>
                {renderTable(vehicleData)}
            </div>
            <>
                <CFAdminDataTable
                    columns={columnValues}
                    data={dataValues}
                    textModal={''}
                    showFilter={false}
                    showAdd={false}
                    showPagination={false}
                    pageSize={1000}
                />
            </>
            <CFAdminConfirmDialog
                isOpen={isDeleteConfirmOpen}
                onClose={()=>setIsDeleteConfirmOpen(false)}
                onConfirm={invokeVehicleDelete}
                title={`Delete Model: ${vehicleDetails.model} and Year: ${vehicleDetails.year}`}
                text="Are you sure you want to delete this Model?"
            />
            <CFAdminConfirmDialog
                isOpen={isDeleteVehicleConfirmOpen}
                onClose={()=>setIsDeleteVehicleConfirmOpen(false)}
                onConfirm={invokeVehicleConfigDelete}
                title={`Delete Configuration: ${vehicleDetails.model} and Year: ${vehicleDetails.year}`}
                text="Are you sure you want to delete this Model?"
            />
            <CFAdminConfirmDialog
                isOpen={isDeleteAttributeConfirmOpen}
                onClose={()=>setIsDeleteAttributeConfirmOpen(false)}
                onConfirm={invokeVehicleAttributeDelete}
                title={`Delete Attribute: ${vehicleDetails.model} and Year: ${vehicleDetails.year}`}
                text="Are you sure you want to delete this Model?"
            />
        </>
    )
}
export default CFAdminVehicleConfigDetails;