import React, {useEffect, useRef, useState} from 'react';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminModal from "../../components/CFAdminModal";
import './CFAdminIntervals.scss'
import CFAdminIntervalForm from './CFAdminIntervalForm';
import IntervalService from '../../services/IntervalService';
import CFAdminNotification from '../../components/CFAdminNotification';
import {DeleteButton, EditButton} from "../../components/CFAdminDataTable/ActionsButtonComponents";
import {ActionMode} from "../../constants/ActionMode";
import {Interval} from "./types";

const renderEditButton = (props: any, handleChange: Function) => {
    return <EditButton {...props} handleChange={handleChange}/>
}

const renderDeleteButton = (props: any, handleDelete: Function, disabled: boolean=false) => {
    return <DeleteButton {...props} handleDelete={handleDelete} disabled={disabled}/>
}
function CFAdminIntervals() {
    const childCompRef = useRef<any>();
    const [data, setData] = useState([] as Interval[]);
    const [isDelete, setIsDelete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [actionText, setActionText] = useState("Add Interval");
    const defaultValues = {intervalId: 0, mode: ActionMode.Add, startValue: 1, endValue: 0, intervalValue: 1,
        startTrigger: 0, endTrigger:0, intervalDesc: "", intervalType: "Mileage", intervalSortKey: 1, userKey:"", modified:""};
    const [initialValues, setInitialValues] = useState(defaultValues);

    const columns = [
        {
            Header: "Description",
            accessor: 'intervalDesc'
        },
        {
            Header: "Start Value",
            accessor: 'startValue'
        },
        {
            Header: "End Value",
            accessor: 'endValue'
        },
        {
            Header: "Interval Value",
            accessor: 'intervalValue'
        },
        {
            Header: "Start Trigger",
            accessor: 'startTrigger'
        },
        {
            Header: "End Trigger",
            accessor: 'endTrigger'
        },
        {
            Header: "Interval Type",
            accessor: 'intervalType'
        },
        {
            Header: "Last Modified By",
            accessor: 'userKey'
        },
        {
            Header: "Last Modified Date",
            accessor: 'modified'
        },
        {
            Header: "Actions",
            Cell: (props:any) => {
                const {row} = props;
                const inUse = row.original.inUse;
                return (
                    <div className="cell-buttons">
                        {renderEditButton(props, handleChange)}
                        {renderDeleteButton(props, handleDelete, inUse)}
                    </div>
                )},
        }
    ];

    const handleChange = (event: any) => {
        setInitialValues({
            ...initialValues,
            mode: ActionMode.Edit,
            intervalId: event.originalRow.intervalId,
            startValue: event.originalRow.startValue,
            endValue: event.originalRow.endValue,
            intervalValue: event.originalRow.intervalValue,
            startTrigger: event.originalRow.startTrigger,
            endTrigger:event.originalRow.endTrigger,
            intervalDesc: event.originalRow.intervalDesc,
            intervalType: event.originalRow.intervalType,
            intervalSortKey: event.originalRow.intervalSortKey,
            userKey: event.originalRow.userKey,
            modified: event.originalRow.modified,
        });
        setActionText('Edit Interval');
        setIsOpen(!isOpen);
    }

    const handleDelete = (event: any) => {
        setInitialValues({
            ...initialValues,
            mode: ActionMode.Edit,
            intervalId: event.originalRow.intervalId,
            startValue: event.originalRow.startValue,
            endValue: event.originalRow.endValue,
            intervalValue: event.originalRow.intervalValue,
            startTrigger: event.originalRow.startTrigger,
            endTrigger:event.originalRow.endTrigger,
            intervalDesc: event.originalRow.intervalDesc,
            intervalType: event.originalRow.intervalType,
            intervalSortKey: event.originalRow.intervalSortKey,
        });
        setIsDelete(true);
        setActionText('Delete Interval');
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        retrieveOperations();
    }, []);


    const retrieveOperations = async () => {
        IntervalService.getAll()
            .then(response => {
                const intervals = response.data.intervalResponses as Interval[];
                setData(intervals.sort((a: Interval, b: Interval) => b.intervalSortKey - a.intervalSortKey));
            })
            .catch(e => {
                console.error(e);
            });
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };


    const toggleModal = () => {
        setInitialValues(defaultValues);
        setActionText('Add Interval');
        setIsOpen(!isOpen);
        setIsDelete(false);
    }

    return (

        <>
            <h2 className="fomo-type--title">Manage Driving Intervals</h2>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={true}
                title={actionText}
            >
                <CFAdminIntervalForm {...initialValues}
                                     isDelete={isDelete}
                                     toggleModal={toggleModal}
                                     refreshData={retrieveOperations}
                                     successMessage={confirmSuccessMessage}
                                     errorMessage={confirmErrorMessage}/>
            </CFAdminModal>

            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={toggleModal}
                textModal={actionText}
            />
            <CFAdminNotification ref={childCompRef}/>
        </>
    );
}

export default CFAdminIntervals;