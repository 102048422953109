import React, {useEffect, useRef, useState} from 'react';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminModal from "../../components/CFAdminModal";
import './CFAdminModels.scss'
import CFAdminModelsForm from './CFAdminModelsForm';
import ModelService from '../../services/ModelService';
import CFAdminNotification from '../../components/CFAdminNotification';
import MakeService from '../../services/MakeService';
import {ModelData} from "./modelData";
import ActionButtons from "../../util/renderActionButtons";


function CFAdminModels() {
    const childCompRef = useRef<any>();
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [actionText, setActionText] = useState("Add Make");
    const [initialValues, setInitialValues] = useState<ModelData>({
        id: 0,
        description: "",
        makeId: 0,
        makeDescription:"",
        country: "",
        language: "",
        userKey:"",
        modified:"",
        usedFlag: true
    });
    const [makes, setMakes] = useState([{code: 0, description: ""}]);
    const columns = [
        {
            Header: "Make",
            accessor: 'makeDescription'
        },
        {
            Header: "Model",
            accessor: 'description'
        },
        {
            Header: "Last Modified By",
            accessor: 'userKey'
        },
        {
            Header: "Last Modified Date",
            accessor: 'modified'
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                const {row} = props;
                const inUse = row.original.usedFlag;
                return (
                    <ActionButtons
                        props={props}
                        handleChange={handleEdit}
                        handleDelete={handleDelete}
                        disabled={inUse}
                    />
                );
            }
        }
    ];

    const triggerModel = (event: any) => {
        setInitialValues({
            ...initialValues,
            id: event.originalRow.id,
            description: event.originalRow.description,
            makeId: event.originalRow.makeId,
            makeDescription: event.originalRow.makeDescription,
            country: event.originalRow.country,
            language: event.originalRow.language,
            usedFlag: event.originalRow.usedFlag
        });
        setIsOpen(!isOpen);
    }

    const handleEdit = (event: any) => {
        setActionText("Edit Model");
        setIsDelete(false);
        triggerModel(event);
    }

    const handleDelete = (event: any) => {
        setActionText("Delete Model");
        setIsDelete(true);
        triggerModel(event);
    }
    useEffect(() => {
        retrieveOperations();
        retrieveMakes();
    }, []);


    const retrieveOperations = async () => {
        ModelService.getAll()
            .then(response => {
                setData(response.data?.modelsResponseModel);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const retrieveMakes = async () => {
        MakeService.getAll()
            .then(response => {
                const makes = response.data?.makesResponseModel.map((make: { id: any; description: any; }) => ({
                    code: make.id,
                    description: make.description,
                }))
                setMakes(makes);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };


    const toggleModal = () => {
        setInitialValues({
            ...initialValues,
            id: 0,
            description: '',
            makeId: 0,
            makeDescription: '',
            country: '',
            language: '',
            usedFlag: true
        });
        setActionText("Add Model");
        setIsOpen(!isOpen);
        setIsDelete(false);
    }

    return (

        <div className="model-component">
            <h2 className="fomo-type--title">Manage Models</h2>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={true}
                title={actionText}
            >
                <CFAdminModelsForm {...initialValues}
                                   makes={makes}
                                   isDelete={isDelete}
                                   toggleModal={toggleModal}
                                   refreshData={retrieveOperations}
                                   successMessage={confirmSuccessMessage}
                                   errorMessage={confirmErrorMessage}/>
            </CFAdminModal>

            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={toggleModal}
                textModal={'Add Model'}
            />
            <CFAdminNotification ref={childCompRef}/>
        </div>
    );
}

export default CFAdminModels;