import './formField.scss';
import { type FieldHookConfig, useField } from 'formik';
import {
  type ClassAttributes,
  type InputHTMLAttributes,
  type SelectHTMLAttributes,
  useEffect,
} from 'react';

type fvpFieldProps = {
  label?: string;
  required?: boolean;
} & FieldHookConfig<string> &
  InputHTMLAttributes<HTMLInputElement> &
  ClassAttributes<HTMLInputElement>;

type optionType = { description: string; code?: string };

const FVPTextInput = ({ label, required = false, ...props }: fvpFieldProps) => {
  if (props.type === undefined) {
    props.type = 'text';
  }
  const propsClassName: string = props.className ?? '';
  props.className = undefined;

  const [field, meta] = useField(props);

  return (
    <label className={'fvp-form__field ' + propsClassName}>
      <span className="fvp-form__label">
        {required && <i className="fvp-form__required"></i>} {label}
      </span>
      <input {...field} {...props} />
      <div className="fvp-form__error">
        {meta.touched && meta.error != null ? meta.error : null}
      </div>
    </label>
  );
};

const FVPTextInputWithSize = ({ label, required = false, ...props }: fvpFieldProps) => {
  if (props.type === undefined) {
    props.type = 'text';
  }
  const propsClassName: string = props.className ?? '';
  props.className = undefined;

  const [field, meta] = useField(props);

  return (
    <label className={'fvp-form__field ' + propsClassName}>
      <span className="fvp-form__label">
        {required && <i className="fvp-form__required"></i>} {label}
      </span>
      <input size={100} {...field} {...props} />
      <div className="fvp-form__error">
        {meta.touched && meta.error != null ? meta.error : null}
      </div>
    </label>
  );
};

const FVPHiddenInput = ({ ...props }: fvpFieldProps) => {
  // Form field for inputs that are present in a form simply so they can hold a
  // value.  they are neither visible to nor editable by a user.
  props.type = 'hidden';

  const [field] = useField(props);

  return <input {...field} {...props} />;
};

const FVPCheckbox = ({ label, required = false, ...props }: fvpFieldProps) => {
  props.type = 'checkbox';
  const propsClassName: string = props.className ?? '';
  props.className = undefined;

  const [field, meta] = useField(props);

  // Several future problems likely with this checkbox.
  // - the error field should really belong to a "checkbox group" (fieldset)
  // - padding with respect to other fields should also belong to said group

  return (
    <label className={'fvp-form__field ' + propsClassName}>
      <div className="fvp-form__check">
        <input {...field} {...props} />
        <span className="fvp-form__label">
          {required && <i className="fvp-form__required"></i>} {label}
        </span>
      </div>
      <div className="fvp-form__error">
        {meta.touched && meta.error != null ? meta.error : null}
      </div>
    </label>
  );
};

type fvpSelectProps = {
  label: string;
  options: optionType[] | null;
  required?: boolean;
  handleChange?: (value: string) => void;
} & FieldHookConfig<string> &
  SelectHTMLAttributes<HTMLSelectElement> &
  ClassAttributes<HTMLSelectElement>;
const FVPSelect = ({
  label,
  options,
  required = false,
  handleChange,
  ...props
}: fvpSelectProps) => {
  const propsClassName: string = props.className ?? '';
  props.className = undefined;

  const [field, meta] = useField(props);

  useEffect(() => {
    if (handleChange != null) {
      handleChange(meta.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.value]);

  let selectHeader = 'Select';
  if (options == null) {
    props.disabled = true;
    selectHeader = '-';
  }

  return (
    <label className={'fvp-form__field ' + propsClassName}>
      <span className="fvp-form__label">
        {required && <i className="fvp-form__required"></i>} {label}
      </span>
      <div className="fvp-form__select" data-disabled={options == null}>
        <select {...field} {...props}>
          <option value="">{selectHeader}</option>
          {options?.map((t: optionType) => (
            <option value={t.code} key={t.description}>
              {t.description}
            </option>
          ))}
        </select>
      </div>
      <div className="fvp-form__error">
        {meta.touched && meta.error != null ? meta.error : null}
      </div>
    </label>
  );
};

export { FVPTextInput, FVPTextInputWithSize, FVPSelect, FVPCheckbox, FVPHiddenInput };
