import DualListBox from "react-dual-listbox";
import React from "react";

function BundleAttributeDetails ({attributeOptions, handleSelectedAttributes, activeStep, bulkBundleConfigs, selectedValues} : any) {
    const handleSelected = (newValue: any) => {
        handleSelectedAttributes(newValue);
    }

    return (
        <>
            <>
                <div className="container">
                    <div className="fvp-form__subgroup">
                        {activeStep === 0 && (
                            <div className="ext1-1">
                                <b> Please select the bundles you want to recommend.</b>
                            </div>
                        )}
                        {activeStep === 1 && (
                            <div className="ext1-1">
                                <b> Please select the Driving Conditions you want to recommend.</b>
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div className="ext1-1">
                                <b> Please select the Intervals you want to recommend.</b>
                            </div>
                        )}
                        <div className="ext1-1 center-align"><b>Please select the Attributes you want to
                            recommend.</b></div>
                        <div className="attribute-value-container">
                            <div className="ext4 scroll">
                                <DualListBox
                                    options={attributeOptions ? attributeOptions : []}
                                    selected={selectedValues}
                                    onChange={(selected: any) => {
                                        handleSelected(selected);
                                    }}
                                    data-testid='attributes-test'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default BundleAttributeDetails