import DualListBox from "react-dual-listbox";
import React, {useEffect, useState} from "react";

function AttributeValueDetails ({id, data, selectedAttributeValues, handleSelectedAttributeValues, vinAttributeValues, enableNextButton} : any) {
    const [selectedAttributes, setSelectedAttributes ] = useState([]);

    const handleSelected = (newValue: any) => {
        setSelectedAttributes(newValue);
        handleSelectedAttributeValues(id, newValue);
    }


    const selectVinAttributes = (newValue :any) => {
        let selectedAttributes: any = selectVinAttributesdata(newValue);
        setSelectedAttributes(selectedAttributes);
        handleSelectedAttributeValues(id, selectedAttributes);
        enableNextButton();
    }

    const selectVinAttributesLength = (newValue :any) :boolean => {
        let selectedAttributes: any = selectVinAttributesdata(newValue);
        return selectedAttributes.length === 0;
    }

    const selectVinAttributesdata = (newValue :any) :[] => {
        let selectedAttributes: any = [];
        newValue.forEach((value: any, key: any) => {

            if(key === id){
                (value.toString()).split(',').forEach(function(selValue: any) {

                    selectedAttributes.push(selValue.toString());
                });
            }
        });
        return selectedAttributes;
    }


    useEffect(() => {
        let selectedAttributes: any = [];
        selectedAttributeValues.forEach((value: any, key: any) => {

            if(key === id){
                (value.toString()).split(',').forEach(function(selValue: any) {

                    selectedAttributes.push(selValue.toString());
                });
            }
        });
        setSelectedAttributes(selectedAttributes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, selectedAttributeValues]);

    return (
        <div>
            Attribute Id - {id}
            <div className="ext4 scroll">
                <DualListBox
                    options={data ? data : []}
                    selected={selectedAttributes}
                    onChange={(selected: any) => {
                        handleSelected(selected);
                        enableNextButton();
                    }}
                />
                <div className="fvp-select-vin-div">
                    <button data-testid="select-vin"
                            className="fvp-select-vin-button" type="button"
                        disabled={selectVinAttributesLength(vinAttributeValues)}
                        onClick={() => selectVinAttributes(vinAttributeValues)}>
                    Select VIN <i className="fvp-right-chevron"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AttributeValueDetails