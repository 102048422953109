import { Formik } from 'formik'
import * as Yup from 'yup';
import { FVPHiddenInput, FVPTextInput, FVPTextInputWithSize } from '../../components/formField';
import DrivingConditionService from '../../services/DrivingConditionService';
import CFAdminTabs from "../../components/CFAdminTabs";
import {CountryLanguageEnum} from "../../constants/CountryLanguageEnum";
import React, {useState} from "react";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";

// Creating schema
const schema = Yup.object().shape({
  drivingConditionId: Yup.number(),
  adminDescId: Yup.number(),
  adminUsSpaDescId: Yup.number(),
  adminCanEngDescId: Yup.number(),
  adminCanFrDescId: Yup.number(),
  drivingConditionSortKey: Yup.number()
    .required("Sort Key is a required field")
    .min(0, "Sort Key must a number greater than 0"),
  adminDesc: Yup.string()
    .required("US English Description is a required field")
    .max(900, "US English Description is allowed maximum of 900 characters"),
  adminUsSpaDesc: Yup.string()
      .max(900, "US Spanish Description is allowed maximum of 900 characters"),
  adminCanEngDesc: Yup.string()
      .max(900, "Canada English Description is allowed maximum of 900 characters"),
  adminCanFrDesc: Yup.string()
      .max(900, "Canada French Description is allowed maximum of 900 characters")
  });

type CFAdminDrivingCondFormProps = {
    drivingConditionId?: number;
    drivingConditionSortKey?: number;
    adminDesc: string;
    adminUsSpaDesc: string;
    adminCanEngDesc: string;
    adminCanFrDesc: string;
    adminDescId?: number;
    adminUsSpaDescId?: number;
    adminCanEngDescId?: number;
    adminCanFrDescId?: number;
    toggleModal: any;
    refreshData: any;
    successMessage: any;
    errorMessage: any;
    isDelete: boolean;
};

function CFAdminDrivingCondForm({
  drivingConditionId,
  drivingConditionSortKey,
  adminDesc,adminDescId,
  adminUsSpaDesc,adminUsSpaDescId,
  adminCanEngDesc,adminCanEngDescId,
  adminCanFrDesc,adminCanFrDescId,
  toggleModal,
  refreshData,
  successMessage,
  errorMessage,
  isDelete
}: CFAdminDrivingCondFormProps) {

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const saveOperation = (values: any) => {
        const data: any = {
            "drivingConditionLanguages" : [
                {
                    "countryLanguageId": CountryLanguageEnum.US_ENG,
                    "description": values.adminDesc,
                },
                {
                    "countryLanguageId":  CountryLanguageEnum.CAN_ENG,
                    "description": values.adminCanEngDesc,
                },
                {
                    "countryLanguageId": CountryLanguageEnum.CAN_FRA,
                    "description": values.adminCanFrDesc,
                },
                {
                    "countryLanguageId": CountryLanguageEnum.US_SPA,
                    "description": values.adminUsSpaDesc,
                }
            ],
            "drivingConditionSortKey": Number(values.drivingConditionSortKey)
        };


    DrivingConditionService.create(data)
        .then(() => {
        successMessage(values.adminDesc+" added successfully.");
        refreshData();
        toggleModal();
      })
      .catch(function (e)  {
          console.error("Error message is " + JSON.stringify(e));
        errorMessage("Error: " + e.response.data.errorMessage);
      });
  };

  const updateOperation = (values: any) => {
    const data = {
      "drivingConditionLanguages" : [{
        "drivingConditionLanguageTextId" : values.adminDescId,
        "countryLanguageId" : CountryLanguageEnum.US_ENG,
        "description" : values.adminDesc
      },{
      "drivingConditionLanguageTextId" : values.adminCanEngDescId,
      "countryLanguageId" : CountryLanguageEnum.CAN_ENG,
        "description" : values.adminCanEngDesc,
      },{
      "drivingConditionLanguageTextId" : values.adminCanFrDescId,
      "countryLanguageId" : CountryLanguageEnum.CAN_FRA,
        "description" : values.adminCanFrDesc,
      },{
      "drivingConditionLanguageTextId" : values.adminUsSpaDescId,
      "countryLanguageId" : CountryLanguageEnum.US_SPA,
        "description" : values.adminUsSpaDesc
      }],
      "drivingConditionSortKey": Number(values.drivingConditionSortKey),
    };

    DrivingConditionService.update(values.drivingConditionId, data)
      .then(() => {
        successMessage(values.adminDesc+" updated successfully.");
        refreshData();
        toggleModal();
      })
        .catch(function (e)  {
            errorMessage("Error: " + e.response.data.errorMessage);
        });
  };

    const deleteOperation = () => {

        if (drivingConditionId) {
            DrivingConditionService.delete(drivingConditionId)
                .then(() => {
                    successMessage(`Operation: ${drivingConditionId} deleted successfully.`);
                    refreshData();
                    toggleModal();
                })
                .catch(e => {
                    console.error(e);
                    errorMessage("Error while deleting Operation!");
                });
        } else {
            errorMessage("Error while deleting Operation!");
        }
    };

  const tabData = [
    { label: "USA" ,
      content: <div><FVPHiddenInput name="adminDescId" /><FVPTextInputWithSize label="Description(English):" size={900}  name="adminDesc" required readOnly={isDelete}/>
        <FVPHiddenInput name="adminUsSpaDescId" /><FVPTextInputWithSize label="Description(Spanish):" size={900}  name="adminUsSpaDesc" readOnly={isDelete}/>
        </div>
    },
    { label: "CANADA",
      content: <div><FVPHiddenInput name="adminCanEngDescId" /><FVPTextInputWithSize label="Description(English):" size={900}  name="adminCanEngDesc"  readOnly={isDelete}/>
        <FVPHiddenInput name="adminCanFrDescId" /><FVPTextInputWithSize label="Description(French):" size={900}  name="adminCanFrDesc"  readOnly={isDelete}/>
      </div>
    },
  ];
  
    return (
        <>
        <Formik
        validationSchema={schema}
        initialValues={{ drivingConditionId: drivingConditionId, drivingConditionSortKey: drivingConditionSortKey, adminDesc: adminDesc,
          adminUsSpaDesc: adminUsSpaDesc, adminCanEngDesc: adminCanEngDesc, adminCanFrDesc: adminCanFrDesc,
            adminDescId: adminDescId,adminUsSpaDescId: adminUsSpaDescId, adminCanEngDescId: adminCanEngDescId,
            adminCanFrDescId: adminCanFrDescId}}
        onSubmit={(values) => {
            if(isDelete){
                setIsDeleteConfirmOpen(true);
            } else {
                if (drivingConditionId !== undefined && drivingConditionId > 0) {
                    updateOperation(values);
                } else {
                    saveOperation(values);
                }
            }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          dirty
        }) => (
          <div className="fvp-form">
            <div className="wide-data-table">
              {/* Passing handleSubmit parameter tohtml form onSubmit property */}
              <form data-testid="SubmitForm" noValidate onSubmit={handleSubmit}>
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                <div className="modal-body">
                    <div className="fvp-form__group">
                    <div className="fvp-form__subgroup">
                        <FVPHiddenInput name="drivingConditionId" />
                        <FVPTextInput label="Sort Key:" name="drivingConditionSortKey" required readOnly={isDelete}/>
                    </div>
                        <CFAdminTabs tabs={tabData} />
                  </div>
                </div>
                <div className="modal-footer button-frame">
                  {/*
                  consider a special class for a collection of buttons; their
                  wrapping should probably be done in a special way (submit to the
                  right when laid out in a row, on top when stacked vertically.)
                  */}
                  <button  data-testid="Cancel"
                    className="fvp-button--outline"
                    onClick={toggleModal}
                  >
                    <i className="fvp-left-chevron" />
                    Cancel
                  </button>

                    {isDelete?
                        <button
                            data-testid="Delete"
                            className="fvp-button-danger--outline"
                            type="submit"
                        >
                            Delete
                            <i className="fvp-right-chevron" />
                        </button> : <button
                            data-testid="Save"
                            className="fvp-button"
                            type="submit"
                            disabled={!(dirty && isValid)}

                        >
                            {values.drivingConditionId === undefined || values.drivingConditionId === 0 ? 'Save' : 'Update'}
                            <i className="fvp-right-chevron" />
                        </button>}
                </div>
            </form>
            </div >
          </div>
        )}
      </Formik>
            <CFAdminConfirmDialog
                isOpen={isDeleteConfirmOpen}
                onClose={()=>setIsDeleteConfirmOpen(false)}
                onConfirm={deleteOperation}
                title={"Delete Operation: " + adminDesc}
                text="Are you sure you want to delete this Operation?"
            />
      </>
    );
  }

export default CFAdminDrivingCondForm;