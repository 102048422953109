import React, {useEffect} from "react";
import CFAdminDataTable from "../../components/CFAdminDataTable";

function CFAdminVehicles ({vehicleDetails, switchToIntervals,  dataValues} : any) {

    const handleVehicleClick = async (event: any) => {
        vehicleDetails.vehicleDrivingConditionId = event.row.original.vehicleDrivingConditionId
        switchToIntervals();
    };

    const columnValues = [
        {
            Header: "Id",
            accessor: 'vehicleDrivingConditionId'
        },
        {
            Header: "Fuel",
            accessor: 'fuelAttributeValueDesc'
        },
        {
            Header: "Transmission",
            accessor: 'transAttributeValueDesc'
        },
        {
            Header: "TransDrivenWheels",
            accessor: 'driveAttributeValueDesc'
        },
        {
            Header: "cylinders",
            accessor: 'cylAttributeValueDesc'
        },
        {
            Header: "Engine",
            accessor: 'engAttributeValueDesc'
        },
        {
            Header: "Vehicle Type",
            accessor: 'vtAttributeValueDesc'
        },
        {
            Header: "Series",
            accessor: 'seriesAttributeValueDesc'
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                return (
                    <div>
                        <button data-testid={`vc-step-next${props.row.index}`}
                                type="button"
                                onClick={() => handleVehicleClick(props)}>
                            Select
                        </button>
                    </div>
                )
            },
        }
    ];

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
            <>
                <CFAdminDataTable
                    columns={columnValues}
                    data={dataValues}
                    showAdd={false}
                    showPagination={false}
                    pageSize={1000}
                />
            </>
    )
}
export default CFAdminVehicles;