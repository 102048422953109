import { authHeader } from "../util/auth-header";
import http from "../util/http-common";

export class BaseService {
  static get(endpoint: string) {
    return http.get(endpoint, { headers: authHeader() });
  }

  static post(endpoint: string, data: any) {
    return http.post(endpoint, data, { headers: authHeader() });
  }

  static put(endpoint: string, id: any, data: any) {
    return http.put(`${endpoint}/${id}`, data, { headers: authHeader() });
  }

  static delete(endpoint: string, id: any) {
    return http.delete(`${endpoint}/${id}`, { headers: authHeader() });
  }
}

export default BaseService;