import React, {useEffect, useRef, useState} from 'react';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminModal from "../../components/CFAdminModal";
import './CFAdminOperations.scss'
import CFAdminOperationForm from './CFAdminOperationForm';
import OperationService from '../../services/OperationService';
import CFAdminNotification from '../../components/CFAdminNotification';
import {Operation, OperationLanguage} from "./types";
import {CountryLanguageEnum} from "../../constants/CountryLanguageEnum";
import {ActionMode} from "../../constants/ActionMode";
import ActionButtons from "../../util/renderActionButtons";

const OperationActionCell = (props: any, handleChange: Function, handleDelete: Function) => {
    return <ActionButtons props={props} handleChange={handleChange} handleDelete={handleDelete}/>
}

function CFAdminOperations() {
    const childCompRef = useRef<any>();
    const [data, setData] = useState([] as Operation[]);
    const [isDelete, setIsDelete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [actionText, setActionText] = useState("Edit Operation");
    const [initialValues, setInitialValues] = useState(
        {opId: 0, mode: ActionMode.Add, opCode: "", avgLts: "", sortkey: "",
            adminDesc: "", adminDescId:0,
            adminUsSpaDesc: "", adminUsSpaDescId: 0,
            adminCanEngDesc: "", adminCanEngDescId: 0,
            adminCanFrDesc:"", adminCanFrDescId:0, modified: "", userKey:""});

    const columns = [
        {
            Header: "PCode",
            accessor: 'operationCode',
            Cell: ({value}: any) => <div className="cell-max-row-height">{value}</div>
        },
        {
            Header: "Description",
            accessor:  (row: Operation) => {
                const operationLanguage: OperationLanguage | undefined = row.operationLanguageList.find(opLang => opLang.countryLanguageId === CountryLanguageEnum.US_ENG);
                return operationLanguage ? operationLanguage.description : '';
            },
            Cell: ({ value }: any) => <div className="cell-left-justified">{value}</div>
        },
        {
            Header: "Last Modified By",
            accessor: 'userKey'
        },
        {
            Header: "Last Modified Date",
            accessor: 'modified'
        },
        {
            Header: "Actions",
            Cell: (props:any) => OperationActionCell(props, handleChange,handleDelete ),
        }
    ];

    const handleChange = (event: any) => {
        const operationLanguageUsEng: OperationLanguage | undefined = event.originalRow.operationLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.US_ENG);
        const operationLanguageUsSpa: OperationLanguage | undefined = event.originalRow.operationLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.US_SPA);
        const operationLanguageCanEng: OperationLanguage | undefined = event.originalRow.operationLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.CAN_ENG);
        const operationLanguageCanFra: OperationLanguage | undefined = event.originalRow.operationLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.CAN_FRA);

        setInitialValues({
            ...initialValues,
            mode: ActionMode.Edit,
            opId: event.originalRow.operationId,
            opCode: event.originalRow.operationCode,
            sortkey: event.originalRow.sortKey,
            avgLts: event.originalRow.avgLTS,
            adminDesc: operationLanguageUsEng ? operationLanguageUsEng.description : '',
            adminDescId:operationLanguageUsEng ? operationLanguageUsEng.languageTextId : 0,
            adminUsSpaDesc: operationLanguageUsSpa ? operationLanguageUsSpa.description : '',
            adminUsSpaDescId: operationLanguageUsSpa ? operationLanguageUsSpa.languageTextId : 0,
            adminCanEngDesc: operationLanguageCanEng ? operationLanguageCanEng.description : '',
            adminCanEngDescId: operationLanguageCanEng ? operationLanguageCanEng.languageTextId : 0,
            adminCanFrDesc: operationLanguageCanFra ? operationLanguageCanFra.description : '',
            adminCanFrDescId: operationLanguageCanFra ? operationLanguageCanFra.languageTextId : 0,
            modified: '',
            userKey: ''
        });
        setIsOpen(!isOpen);
    }

    const handleDelete = (event: any) => {
        setActionText("Delete Operation");
        setIsDelete(true);
        handleChange(event);
    }

    useEffect(() => {
        retrieveOperations();
    }, []);


    const retrieveOperations = async () => {
        OperationService.getAll()
            .then(response => {
                const operations = response.data.operationList as Operation[];
                const sortedOperations = operations.sort((a: Operation, b: Operation) => b.sortKey - a.sortKey);
                setData(sortedOperations);
            })
            .catch(e => {
                console.error(e);
            });
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };


    const toggleModal = () => {
        setInitialValues({
            ...initialValues,
            opId: 0, mode: ActionMode.Add, opCode: "", avgLts: "", sortkey: "",
            adminDesc: "", adminDescId:0,
            adminUsSpaDesc: "", adminUsSpaDescId: 0,
            adminCanEngDesc: "", adminCanEngDescId: 0,
            adminCanFrDesc:"", adminCanFrDescId:0
        });
        setIsOpen(!isOpen);
        setActionText("Edit Operation");
        setIsDelete(false);
    }

    return (

        <div className="operations-component">
            <h2 className="fomo-type--title">Operations List</h2>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={true}
                title={actionText}
            >
                <CFAdminOperationForm {...initialValues}
                                      isDelete={isDelete}
                                      toggleModal={toggleModal}
                                      refreshData={retrieveOperations}
                                      successMessage={confirmSuccessMessage}
                                      errorMessage={confirmErrorMessage}/>
            </CFAdminModal>

            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={toggleModal}
                textModal={'Add Operation'}
            />
            <CFAdminNotification ref={childCompRef}/>
        </div>
    );
}

export default CFAdminOperations;