import { authHeader } from "../util/auth-header";
import http from "../util/http-common";

export class RecommendationService {

  static getAttributes() {
    return http.get("/cfadminvehicleconfig/api/v1/attributes/for-recommendations",{ headers: authHeader()});
  }

  static getAttributeValues(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/attribute-values-query/for-recommendations",data,{ headers: authHeader()});
  }

  static createBulkBundle(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-bundles-bulk",data,{ headers: authHeader()});
  }

  static deleteBulkBundle(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-bundles-bulk/delete",data,{ headers: authHeader()});
  }

  static createBulkOperation(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-operation-bulk",data,{ headers: authHeader()});
  }

  static deleteBulkOperation(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-operation-bulk/delete",data,{ headers: authHeader()});
  }

  static getModelYearsByModelAndOperation(modelId: any, operationId: any) {
    return http.get(`/cfadminvehicleconfig/api/v1/vehicle-operation-bulk/model-years-for-model-operation?modelId=${modelId}&operationId=${operationId}`, { headers: authHeader()});
  }

  static getYearModels(year: string) {
    return http.get(`/cfadminvehicleconfig/api/v1/mmy/model-years-gte?year=${year}`,{ headers: authHeader()});
  }

  static cloneVehicles(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle/clone-vehicles",data,{ headers: authHeader()});
  }

  static copyVehicles(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle/copy-vehicle-recommendations",data,{ headers: authHeader()});
  }

  static getIolmIntervals() {
    return http.get(`/cfadminvehicleconfig/api/v1/vehicle-driving-conditions-query/iolm-multiple-intervals`,{ headers: authHeader()});
  }

  static getAllVehicleBundles(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-bundles-bulk/get-models-by-bundle-year",data,{ headers: authHeader()});
  }

  static getAllModels() {
    return http.get(`/cfadminvehicleconfig/api/v1/vehicle-operation-bulk/models`, { headers: authHeader()});
  }

  static getVehicleBundles(bundleId: any, modelId: any, yearId: any) {
    return http.get(`/cfadminvehicleconfig/api/v1/vehicle-bundles-bulk/bundles?bundleId=${bundleId}&modelId=${modelId}&yearId=${yearId}`, { headers: authHeader()});
  }

  static saveVehicleBundles(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-bundle",data,{ headers: authHeader()});
  }

  static getVehicleOperations(operationId: any, modelId: any, yearIds: any, drivingConditionId: any, intervalId:any) {
    const parameters: any = {};
    const yearIdsArray = Array.isArray(yearIds) ? yearIds : [yearIds];

    parameters.operationId = operationId;
    parameters.modelId = modelId;
    if(yearIdsArray && yearIdsArray.length > 0) parameters.yearIds = yearIdsArray.join(',');
    if(drivingConditionId) parameters.drivingConditionId = drivingConditionId;
    if(intervalId) parameters.intervalId = intervalId;
    if(drivingConditionId && intervalId){
      return http.get(`/cfadminvehicleconfig/api/v1/vehicle-operation-bulk/operations-by-dcond-interval` ,{ params:parameters, headers: authHeader()});
    }else{
      return http.get(`/cfadminvehicleconfig/api/v1/vehicle-operation-bulk/operations` ,{ params:parameters, headers: authHeader()});
    }

  }

  static saveVehicleOperations(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-operations",data,{ headers: authHeader()});
  }

}

export default RecommendationService;