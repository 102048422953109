import React from "react";

function CFAdminVinImportDetails ({latestImportData} : any) {

    return (
        <>
            <div className="ext1">
                <table >
                    <thead>
                    <tr>
                        <th>VIN Data Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="">
                        <td><b>Last Import date</b></td>
                        <td><b>Polk Version</b></td>
                        <td><b>Last released date to WSM</b></td>
                        <td><b>Last released date to Internal Systems</b></td>
                    </tr>
                    <tr className="">
                        <td>{latestImportData.lastImportDate}</td>
                        <td>{latestImportData.polkVersion}</td>
                        <td>{latestImportData.lastWSMReleaseDate}</td>
                        <td>{latestImportData.lastQCMReleaseDate}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CFAdminVinImportDetails;