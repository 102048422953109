import React from "react";
import './CFAdminConfirmDialog.scss';


interface ConfirmDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    text: string;
    overlayClassName?: string;
}

export const CFAdminConfirmDialog: React.FC<ConfirmDialogProps> = ({
                                                         isOpen,
                                                         onClose,
                                                         onConfirm,
                                                         title,
                                                         text,
                                                         overlayClassName
                                                     }) => {
    return (
        <>
            {isOpen && (
                <div className={overlayClassName || "modal-overlay"}>
                    <div className="confirm-modal-box">
                        <h3>{title}</h3>
                        <p>{text}</p>
                        <button data-testid="ConfirmYes" onClick={onConfirm}>Yes</button>
                        <button data-testid="ConfirmCancel" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CFAdminConfirmDialog;