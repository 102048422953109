import React from 'react';
import {Route, Routes} from "react-router-dom";
import CFAdminLanding from '../pages/CFAdminLanding';
import CFAdminHome from '../pages/CFAdminHome';
import CFAdminCountries from '../pages/CFAdminCountries';
import CFAdminLanguage from '../pages/CFAdminLanguage';
import CFAdminOperations from '../pages/CFAdminOperations';
import CFAdminCallback from '../pages/CFAdminCallback';
import CFAdminMakes from '../pages/CFAdminMakes';
import {AppProps} from '../components/CFAdminNavigation/types';
import CFAdminAccess from '../pages/CFAdminAccess';
import CFAdminModels from '../pages/CFAdminModels';
import CFAdminCountryLanguages from "../pages/CFAdminCountryLanguages";
import CFAdminBundles from '../pages/CFAdminBundles';
import CFAdminIntervals from "../pages/CFAdminIntervals";
import CFAdminDrivingConds from "../pages/CFAdminDrivingConds";
import CFAdminVehicleConfig from "../pages/CFAdminVehicleConfig";
import CFAdminMaintenanceSchedule from "../pages/CFAdminMaintenanceSchedule";
import CFAdminNotFound from "../pages/CFAdminNotFound";
import CFAdminBulkBundle from "../pages/CFAdminBulkBundle";
import CFAdminCloneVehicle from "../pages/CFAdminCloneVehicle";
import CFAdminCopyMaintenance from "../pages/CFAdminCopyMaintenance";
import CFAdminIolmIntervals from "../pages/CFAdminIolmIntervals";
import CFAdminVehicleBundle from "../pages/CFAdminVehicleBundle";
import CFAdminPublish from "../pages/CFAdminPublish";
import CFAdminVinImport from "../pages/CFAdminVinImport";

function AppRoutes(user : AppProps) {
    return(
      
      <Routes>
        <Route path="/" element={<CFAdminLanding {...user} />}>
                <Route index element={<CFAdminHome />}></Route>
                <Route path="/countries" element={<CFAdminCountries />}></Route>
                <Route path="/language" element={<CFAdminLanguage />}></Route>
                <Route path="/countryLanguages" element={<CFAdminCountryLanguages />}></Route>
                <Route path="/operations" element={<CFAdminOperations />}></Route>
                <Route path="/makes" element={<CFAdminMakes />}></Route>
                <Route path="/models" element={<CFAdminModels />}></Route>
                <Route path="/attributes" element={<CFAdminModels />}></Route>
                <Route path="/bundles" element={<CFAdminBundles />}></Route>
                <Route path="/accessrestrict" element={<CFAdminAccess />}></Route>
                <Route path="/intervals" element={<CFAdminIntervals />}></Route>
                <Route path="/drivingconds" element={<CFAdminDrivingConds />}></Route>
                <Route path="/createnewveh" element={<CFAdminVehicleConfig screen={'create'}/>}></Route>
                <Route path="/createconfig" element={<CFAdminVehicleConfig screen={'edit'}/>}></Route>
                <Route path="/systemsuggconfig" element={<CFAdminVehicleConfig screen={'suggestedconfig'}/>}></Route>
                <Route path="/systemsuggvehicle" element={<CFAdminVehicleConfig screen={'suggestedveh'}/>}></Route>
                <Route path="/unsupportvehicle" element={<CFAdminVehicleConfig screen={'unsupportvehicle'}/>}></Route>
                <Route path="/maintenanceschedule" element={<CFAdminMaintenanceSchedule />}></Route>
                <Route path="/bulkBundleAssignment" element={<CFAdminBulkBundle screen={'bulkbundle'}/>}></Route>
                <Route path="/bulkOperationAssignment" element={<CFAdminBulkBundle screen={'bulkoperation'}/>}></Route>
                <Route path="/cloneVehicleProfile" element={<CFAdminCloneVehicle />}></Route>
                <Route path="/copyMaintenance" element={<CFAdminCopyMaintenance />}></Route>
                <Route path="/iolmIntervals" element={<CFAdminIolmIntervals />}></Route>
                <Route path="/vehicleBundleAssignment" element={<CFAdminVehicleBundle screen={'vehiclebundle'}/>}></Route>
                <Route path="/vehicleOperationAssignment" element={<CFAdminVehicleBundle screen={'vehicleoperation'}/>}></Route>
                <Route path="/publish" element={<CFAdminPublish />}></Route>
                <Route path="/vinimport" element={<CFAdminVinImport />}></Route>

                <Route path="*" element={<CFAdminNotFound />}></Route>
        </Route>
         <Route path="/callback" element={<CFAdminCallback />}></Route>
         
      </Routes>
  
    );
}

export default AppRoutes;
