export function authHeader() {
    const oauthToken = window.localStorage.getItem("awsmodtoken");
    if (oauthToken) {
      return { Authorization: 'Bearer ' + oauthToken };
    } else {
      return { };
    }
}

export function authCodeHeader(code: any) {
    return { 'X-access-code': code };
}

export function authRefreshHeader() {
  const rwsmodtoken = window.localStorage.getItem("rwsmodtoken");
  return { 'X-Refresh-code': rwsmodtoken };
}