import CFAdminNotification from "../../components/CFAdminNotification";
import React, {useEffect, useRef, useState} from "react";
import {DataPublishService} from "../../services/DataPublishService";
import "./CFAdminPublish.scss";
import Accordion from "./Accordion";
import CFAdminModal from "../../components/CFAdminModal";
import CFAdminDataTable from "../../components/CFAdminDataTable";
interface Column {
    Header: string;
    accessor: string;
}

function CFAdminPublish() {
    const childCompRef = useRef<any>();
    const [tablesList, setTablesList] = useState<Map<string, any[]>>(new Map<string, any[]>());
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [columns, setColumns] = useState<Column[]>([]);
    const [data, setData] = useState<any[]>([]); // Explicit type annotation for data
    const [isLoaded, setIsLoaded] = useState(false);
    const [heading, setHeading] = useState('Data Publishing');

    const toggleAccordion = (key: any, index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
        const tableData = tablesList.get(key);
        if (tableData) {
            setHeading(key);
            setData(tableData);
            setColumns(flattenObjectKeys(tableData[0]).map((key: any) => {
                return {
                    Header: key,
                    accessor: key
                };
            }));

            toggleModal();
        }
    };

    const isObject = (value: any) => typeof value === 'object' && value !== null;

    const shouldIncludeKey = (newKey: string) => {
        return newKey.indexOf('.') === -1 ||
            ((newKey.endsWith('.admin') || newKey.endsWith('.id')) &&
                newKey.indexOf('.') === newKey.lastIndexOf('.'));
    };

    const flattenObjectKeys = (obj: any, parentKey = '', result: any = {}) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = parentKey ? `${parentKey}.${key}` : key;
                if (isObject(obj[key])) {
                    flattenObjectKeys(obj[key], newKey, result);
                } else {
                    if (shouldIncludeKey(newKey)) {
                        result[newKey] = obj[key];
                    }
                }
            }
        }
        return Object.keys(result);
    };

    const retrievePublishData = async () => {
        DataPublishService.getAll()
            .then(response => {

                let publishChanges = response.data.changes;
                const dataTables: Map<string, any[]> = new Map<string, any[]>();
                if (publishChanges){
                    publishChanges.forEach((element: any) => {
                        if(element.changedRows){
                            const dataColumns: any[] = [];
                            element.changedRows.forEach((row: any) => {
                                const jsonObject = JSON.parse(row.rowData);
                                jsonObject.action = row.action;
                                if (jsonObject.modified) {
                                    jsonObject.modified = new Date(jsonObject.modified).toLocaleString();
                                }
                                delete jsonObject.deleted;
                                dataColumns.push(jsonObject);
                            });
                            dataTables.set(element.tableName, dataColumns);
                        }
                    });
                }
                if(dataTables.size > 0) {
                    setIsLoaded(true);
                    setTablesList(dataTables);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const publishData = () => {
        return DataPublishService.publish()
            .then(response => {
                confirmSuccessMessage("Data published successfully.");
                setTablesList(new Map<string, any[]>());
                setIsLoaded(false);
                retrievePublishData();
            })
            .catch(e => {
                console.log(e);
                confirmErrorMessage("Data publish failed.");
            });
    };

    useEffect(() => {
        retrievePublishData();
    }, []);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    return (
        <>
            <h2 className="fomo-type--title">Data Publishing</h2>
            <div className="scrollable-div">
                <div className="accordion">
                    {
                        tablesList.size > 0 && Array.from(tablesList.entries()).map(([key, value], index) => (
                            <Accordion
                                key={key}
                                title={key}
                                content={value}
                                isActive={activeIndex === index}
                                toggleAccordion={() => toggleAccordion(key, index)}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="right-align">
                <button data-testid="publish-next"
                        className="fvp-button button-right-align" type="button"
                        onClick={() => publishData()}
                        disabled={!isLoaded}>
                    Publish
                </button>
            </div>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={true}
                title={heading}
            >
                <CFAdminDataTable
                    columns={columns}
                    data={data}
                    showAdd={false}
                />
            </CFAdminModal>
            <CFAdminNotification ref={childCompRef}/>
        </>

    );

}

export default CFAdminPublish;