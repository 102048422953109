import React, { useMemo } from 'react';
import { usePagination, useTable, useSortBy, useGlobalFilter, useExpanded, TableInstance } from 'react-table';
import './CFAdminDataTable.scss';

type DatatableProps = {
    columns: any;
    data: any;
    toggleModal?: any;
    textModal?: any;
    showFilter?: boolean;
    showAdd?: boolean;
    showPagination?: boolean;
    pageSize?: number;
    pageIndex?: number;
    tdClassName?: string;
};

export const useRowSpan = (instance: TableInstance) => {
    const { allColumns, state: { pageIndex } } = instance;
    const rowSpanHeaders = useMemo(() => {
        let headers: any = [];
        allColumns.forEach((column: any) => {
            const { id, enableRowSpan } = column;
            if (enableRowSpan) {
                headers = [
                    ...headers,
                    { id, topCellValue: null, topCellIndex: 0 },
                ];
            }
        });
        return headers;
    }, [allColumns]);

    Object.assign(instance, { rowSpanHeaders });

    React.useEffect(() => {
        rowSpanHeaders.forEach((header: any) => {
            header.topCellValue = null;
            header.topCellIndex = 0;
        });
    }, [pageIndex, rowSpanHeaders]);
};

export const CFAdminDataTable = ({
                                     columns,
                                     data,
                                     toggleModal,
                                     textModal,
                                     showFilter = true,
                                     showAdd = true,
                                     showPagination = true,
                                     pageSize = 10,
                                     pageIndex = 0,
                                     tdClassName
                                 }: DatatableProps) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        prepareRow,
        rowSpanHeaders,
        setGlobalFilter
    } = useTable({
            // @ts-ignore
            columns,
            data,
            autoResetGlobalFilter: false,
            initialState: { pageIndex, pageSize }
        },
        (hooks) => hooks.useInstance.push(useRowSpan),
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
    );

    // value and onChange function
    const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        setGlobalFilter(value);
    };

    const { pageIndex: currentPageIndex, pageSize: currentPageSize } = state;

    return (
        <div>
            <div className='split-screen'>
                {showFilter && (<div className='search-frame'>
                    <input type="search" className='search-input' placeholder="Filter"
                           onChange={handleFilterInputChange} />
                </div>)}
                {showAdd && (<div style={{ display: textModal }}>
                    <button data-testid="add-button"
                            className="fvp-button"
                            onClick={toggleModal}
                    >
                        {textModal}
                    </button>
                </div>)}
            </div>

            <div className="table">
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        style={{
                                            borderBottom: 'solid 3px blue',
                                            background: 'aliceblue',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }}>
                                        {column.render("Header")}
                                        <span></span>
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row: any, i: any) => {
                        prepareRow(row);

                        for (let j = 0; j < row.allCells.length; j++) {
                            let cell: any = row.allCells[j];
                            let rowSpanHeader = rowSpanHeaders.find(
                                (x: any) => x.id === cell.column.id
                            );
                            if (rowSpanHeader) {
                                if (
                                    rowSpanHeader.topCellValue === null ||
                                    rowSpanHeader.topCellValue !== cell.value
                                ) {
                                    cell.isRowSpanned = false;
                                    rowSpanHeader.topCellValue = cell.value;
                                    rowSpanHeader.topCellIndex = i;
                                    cell.rowSpan = 1;
                                } else {
                                    (page[rowSpanHeader.topCellIndex].allCells[j] as any)
                                        .rowSpan++;
                                    cell.isRowSpanned = true;
                                }
                            }
                        }
                        return null;
                    })}
                    {page.map((row: any) => {
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    if (cell.isRowSpanned) return null;
                                    else
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                rowSpan={cell.rowSpan}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            {showPagination && (<>
                <div className="table-pagination"
                     style={{ margin: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </button>
                    <span>
                        <strong style={{ display: 'block', width: '100px', textAlign: 'center' }}>
                            {currentPageIndex + 1} / {pageOptions.length}
                        </strong>
                    </span>
                    <span className="table-pagination-goto">
                        Go to page: {' '}
                        <input type="number" defaultValue={currentPageIndex + 1}
                               onChange={(e) => {
                                   const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                   gotoPage(pageNumber);
                               }}
                               style={{ width: '50px' }} />
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>
                </div>
                <div className="table-pagesize"
                     style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <select value={currentPageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                        {
                            [10, 25, 50].map(size => (
                                <option key={size} value={size}>
                                    {size} records
                                </option>
                            ))
                        }
                    </select>
                </div>
            </>)}
        </div>
    );
}

export default CFAdminDataTable;