import { authHeader } from "../util/auth-header";
import http from "../util/http-common";

export class MakeService {
  static getAll() {
    return http.get("/cfadminsysmaintain/api/v1/makes",{ headers: authHeader()});
  }

  static create(data: any) {
    return http.post("/cfadminsysmaintain/api/v1/makes", data, { headers: authHeader()});
  }

  static update(id: number, data: any) { //id to pass
    return http.put(`/cfadminsysmaintain/api/v1/makes/${id}`, data, { headers: authHeader()});
  }

  static delete(id: any) {
    return http.delete(`/cfadminsysmaintain/api/v1/makes/${id}`,{ headers: authHeader()});
  }

}

export default MakeService;