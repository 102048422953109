import {CellProps, HeaderProps} from "react-table";
import React from "react";
import {RowData} from "./types";

export const ExpandCollapseHeader = ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: HeaderProps<RowData>) => (
    <span {...getToggleAllRowsExpandedProps()}>
    {isAllRowsExpanded ? '-' : '+'}
  </span>
);

export const ExpandCollapseCell = ({ row }: CellProps<RowData>) => row.canExpand ? (
    <span
        {...row.getToggleRowExpandedProps({
            style: {
                paddingLeft: `${row.depth * 2}rem`,
            },
        })}
    >
    {row.isExpanded ? '-' : '+'}
  </span>
) : null;
