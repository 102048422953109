import React from 'react';
const Accordion = ({ title, content, isActive, toggleAccordion } : any) => {
    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={toggleAccordion}>
                <div>{title}</div>
                <div>{isActive ? '-' : '+'}</div>
            </div>
        </div>
    );
};

export default Accordion;