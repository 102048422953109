import React from 'react';


function CFAdminNotFound() {


    return (

        <div className="make-component">
            <h2 className="fomo-type--title">Development is in progress...</h2>
        </div>
    );
}

export default CFAdminNotFound;