import React from "react";
import CFAdminDataTable from "../../components/CFAdminDataTable";

function CFAdminVehicleConfigDetails ({columnValues, dataValues, totalRecords, desc} : any) {
    return (
        <>
            <div className="ext2">
                * {totalRecords} vehicle configurations were found for the vehicle (<b>{desc}</b>) listed below
            </div>
            <>
                {columnValues &&  <CFAdminDataTable
                    columns={columnValues}
                    data={dataValues}
                    textModal={''}
                    showFilter={false}
                    showAdd={false}
                    showPagination={false}
                    pageSize={1000}
                /> }
            </>
        </>
    )
}

export default CFAdminVehicleConfigDetails;