import './CFAdminBackground.scss';
import FordLoader  from '../../assets/images/home.png';

type BackgroundProps = {
  children: React.ReactNode;
  color?: string;
};

export default function CFAdminBackground({
  children,
  color,
}: BackgroundProps) {

  const style = {
    '--fvp-page-background-color': color,
    '--fvp-page-background-image': FordLoader,
  } as React.CSSProperties;

  return (
    <div className="fvp-page-background" style={style}>
      {children}
    </div>
  );
}
