import DualListBox from "react-dual-listbox";
import React, {useEffect, useState} from "react";

function BundleAttributeValueDetails ({id, data, selectedAttributeValues, handleSelectedAttributeValues} : any) {
    const [selectedAttributes, setSelectedAttributes ] = useState([]);

    const handleSelected = (newValue: any) => {
        setSelectedAttributes(newValue);
        handleSelectedAttributeValues( id, newValue);
    }

    useEffect(() => {
        let selectedAttributes: any = [];
        selectedAttributeValues.forEach((value: any, key: any) => {

            if(key === id){
                (value.toString()).split(',').forEach(function(selValue: any) {
                    selectedAttributes.push(selValue.toString());
                });
            }
        });
        setSelectedAttributes(selectedAttributes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, selectedAttributeValues]);

    return (
        <div>
            <b> {id} </b>
            <div className="ext4 scroll">
                <DualListBox
                    options={data ? data : []}
                    selected={selectedAttributes}
                    onChange={(selected: any) => {
                        handleSelected(selected);
                    }}
                />
            </div>
        </div>
    )
}

export default BundleAttributeValueDetails