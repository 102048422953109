import { BaseService } from "./BaseService";

export class BundleService extends BaseService {
  static getAll() {
    return this.get("/cfadminsysmaintain/api/v1/bundles");
  }

  static create(data: any) {
    return this.post("/cfadminsysmaintain/api/v1/bundles", data);
  }

  static update(id: any, data: any) {
    return this.put("/cfadminsysmaintain/api/v1/bundles", id, data);
  }

  static delete(id: any) {
    return super.delete("/cfadminsysmaintain/api/v1/bundles", id);
  }
}

export default BundleService;