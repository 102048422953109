import React, {useEffect, useRef, useState} from "react";
import {Formik, FormikErrors, FormikValues} from 'formik';
import * as Yup from 'yup';
import Stepper from 'react-stepper-horizontal';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import {CheckboxButton} from "../../components/CFAdminDataTable/ActionsButtonComponents";
import CFAdminBundleConfirmation from "./CFAdminBundleConfirmation";
import {Bundle, BundleOperation} from "./index";
import OperationService from "../../services/OperationService";
import {Operation} from "../CFAdminOperations/types";
import {FVPTextInput} from "../../components/formField";
import BundleService from "../../services/BundleService";

const schema = Yup.object().shape({
    bundleCode: Yup.string()
        .required("*Bundle Code is a required field")
        .min(1, "*Bundle Code must be at least 1 characters")
        .max(10, "*Bundle Code must be at most 10 characters"),
    bundleDescription: Yup.string()
        .required("*Bundle Description is a required field")
        .max(900, "*Bundle Description is allowed maximum of 900 characters")
});

type CFAdminBundlesProps = {
    bundleId: number,
    bundleDescription: string,
    bundleCode: string,
    operations: BundleOperation[],
    userKey: string;
    modified: string;
    toggleModal: any;
    refreshData: any;
    successMessage: any;
    errorMessage: any;
    isDelete: boolean;
};

const CFAdminBundlesForm = ({
                                bundleId,
                                bundleDescription,
                                bundleCode,
                                operations,
                                userKey,
                                modified,
                                isDelete,
                                toggleModal,
                                refreshData,
                                successMessage,
                                errorMessage
                            }: CFAdminBundlesProps) => {
    const getInitialSelected = (operations: BundleOperation[]) => {
        return operations?.reduce((acc: Record<number, boolean>, operation: BundleOperation) => {
            acc[operation.operationId] = true;
            return acc;
        }, {});
    };
    const [pendingBundle, setPendingBundle] = useState<Bundle>({
        operations: [],
        bundleId,
        bundleCode,
        bundleDescription,
        userKey,
        modified,
    });
    const [pendingOperations, setPendingOperations] = useState(operations);
    const [activeStep, setActiveStep] = useState(isDelete ? 2 : 0);
    const [nextStep, setNextStep] = useState(0);
    const [selectedIds, setSelectedIds] = useState<Record<number, boolean>>(getInitialSelected(operations));
    const [allOperations, setAllOperations] = useState([]);


    const steps = [
        {title: 'Bundle details', onClick: () => setActiveStep(0)},
        {title: 'Add Operations', onClick: () => setActiveStep(1)},
        {title: 'Review and confirm', onClick: () => setActiveStep(2)},
    ];

    const Confirmation = () => <React.Fragment><CFAdminBundleConfirmation
        bundleId={bundleId}
        bundleCode={pendingBundle.bundleCode}
        bundleDescription={pendingBundle.bundleDescription}
        isDelete={isDelete}
        operations={pendingOperations}
        handleNext={handleStepperNext}
        activeStep={activeStep}
        nextStep={nextStep}
    />
    </React.Fragment>;

    const retrieveAllOperations = () => {
        OperationService.getAll()
            .then(response => {
                const operationsWithIndex = response.data.operationList.map((operation: Operation, index: number) => {
                    // Check if the operation is in the selectedOperations array
                    const isSelected = selectedIds[operation.operationId] || false;
                    const usEnDescription: string | undefined = operation.operationLanguageList?.find(item => item.countryLanguageId === 1)?.description;
                    return {
                        ...operation,
                        operationDescription: usEnDescription,
                        index: index + 1,  // Adding 1 so the index starts from 1 instead of 0
                        selected: isSelected  // Add the selected property
                    };
                });
                setAllOperations(operationsWithIndex);
            })
    };

    useEffect(() => {
        retrieveAllOperations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        switch (activeStep) {
            case 0:
            case 2:
                if (nextStep === 1) {
                    handleStepperNext(1);
                }
                break;
            case 1:
                if (nextStep === 2) {
                    handleStepperNext(2);
                } else if (nextStep === 0) {
                    handleStepperNext(0);
                }
                break;
            default:
                console.error("Unrecognized step");
        }
    }, [activeStep, nextStep]);

    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };

    const handleNextClick = (values: any) => {
        setPendingBundle(prevBundle => ({
            ...prevBundle,
            bundleCode: values.bundleCode,
            bundleDescription: values.bundleDescription
        }));
        setNextStep(activeStep + 1);
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    const handleOperationSelected = (event: any, operation: BundleOperation) => {
        setSelectedIds((prevState) => {
            const isSelected = !prevState[operation.operationId];
            if (isSelected) {
                setPendingOperations([...pendingOperations, operation]);
            } else {
                setPendingOperations(pendingOperations.filter((item) => item.operationId !== operation.operationId));
            }
            return {...prevState, [operation.operationId]: isSelected};
        });
    };

    const renderSelectButton = (props: any, handleChange: Function) => {
        const {operationId} = props.row.original;
        const selected = selectedIds[operationId] || false;
        return <CheckboxButton {...props} checked={selected} handleChange={handleChange}/>;
    };
    const columns = [
        {
            Header: 'Selected',
            accessor: 'selected',
            Cell: (props: any) => {
                return <div>{renderSelectButton(props, () => handleOperationSelected(props, props.row.original))}</div>;
            }
        },
        {Header: "Index", accessor: 'index'},
        {Header: "Operation Code", accessor: 'operationCode'},
        {Header: "Operation Description", accessor: 'operationDescription', tdClassName: "left-align"}
    ];

    const updateBundle = (values: Bundle) => {
        const updateRequest: any = {
            bundleCode: values.bundleCode,
            bundleDescription: values.bundleDescription,
            countryLanguageTextId: values.countryLanguageTextId,
            operationIds: values.operations.map(operation => operation.operationId)
        }

        BundleService.update(values.bundleId, updateRequest)
            .then(() => {
                successMessage(`Bundle: ${values.bundleId} updated successfully.`);
                refreshData();
                toggleModal();
            })
            .catch(error => {
                errorMessage(`Bundle: ${values.bundleId} was not updated. Error: ${error.message}`);
            });
    };

    const saveBundle = (values: Bundle) => {
        const createRequest: any = {
            bundleCode: values.bundleCode,
            bundleDescription: values.bundleDescription,
            countryLanguageTextId: values.countryLanguageTextId,
            operationIds: values.operations.map(operation => operation.operationId)
        }

        BundleService.create(createRequest)
            .then((response) => {
                const bundleCode: string = response.data.bundleCode;
                successMessage(`Bundle: ${bundleCode} created successfully.`);
                refreshData();
                toggleModal();
            })
            .catch(error => {
                console.error(error);
                const serverMessage = error.response?.data?.errorMessage;
                if (serverMessage) {
                    errorMessage(`${serverMessage}`);
                } else {
                    errorMessage(`Could not save Bundle.`);
                }
            });
    };

    const deleteBundle = (values:any) => {

        BundleService.delete(values.bundleId)
            .then(() => {
                const bundleCode: string = values.bundleCode;
                successMessage(`Bundle: ${bundleCode} deleted successfully.`);
                refreshData();
            })
            .catch(error => {
                console.error(error);
                const serverMessage = error.response?.data?.errorMessage;
                if (serverMessage) {
                    errorMessage(`${serverMessage}`);
                } else {
                    errorMessage(`Could not delete Bundle.`);
                }
            })

    }

    const handleSubmit = (values: any) => {
        if (isDelete) {
            deleteBundle(values);
        }
        else if (bundleId !== undefined && bundleId > 0) {
            const updatedBundle: Bundle = {
                bundleId: values.bundleId,
                bundleCode: values.bundleCode,
                bundleDescription: values.bundleDescription,
                operations: pendingOperations,
                userKey: values.userKey,
                modified: values.modified
            }
            updateBundle(updatedBundle);
        } else {
            const newBundle: Bundle = {
                bundleId: 0,
                bundleCode: values.bundleCode,
                bundleDescription: values.bundleDescription,
                operations: pendingOperations,
                userKey: values.userKey,
                modified: values.modified
            }
            saveBundle(newBundle);
        }
        isDelete = false;
        toggleModal();
    };

    const bundleCodeRef = useRef<any>();

    const isNextDisabled = (isValid: boolean, errors: FormikErrors<FormikValues>): boolean => {
        let status = false;
        switch (activeStep) {
            case 0:
                if (!isValid && (errors.bundleCode || errors.bundleDescription)) {
                    status = true;
                }
                break;
            case 1:
                if (pendingOperations.length === 0) {
                    status = true;
                }
                break;
            default:
        }
        return status;
    };

    return (
        <Formik
            validationSchema={schema}
            initialValues={{
                bundleId: bundleId,
                bundleCode: bundleCode,
                bundleDescription: bundleDescription,
                operations: pendingOperations
            }}
            onSubmit={handleSubmit}
            validateOnMount={true}
        >
            {({values, errors, handleSubmit, isValid}) => (
                <div className="fvp-form">
                    <div className="modal-top-div">
                        <form noValidate onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="fvp-form__group">
                                    <div className="stepper">
                                        <Stepper steps={steps} activeStep={activeStep}/>
                                        <div className="active-step">
                                            {activeStep === 0 &&
                                                <>
                                                    <FVPTextInput
                                                        ref={bundleCodeRef}
                                                        data-testid="bundle-code-input"
                                                        className="bundle-code-input"
                                                        label="Bundle Code:"
                                                        name="bundleCode"
                                                        required
                                                    />
                                                    <FVPTextInput
                                                        data-testid="bundle-desc-input"
                                                        className="bundle-description-input"
                                                        label="Bundle Description:"
                                                        name="bundleDescription"
                                                        required
                                                    />
                                                </>
                                            }
                                            {activeStep === 1 && (
                                                <>
                                                    <CFAdminDataTable
                                                        columns={columns}
                                                        data={allOperations}
                                                        textModal={'Select Operations'}
                                                        showAdd={false}
                                                        showPagination={false}
                                                        pageSize={1000}
                                                    /> {!isValid && <div className="fvp-form__error">
                                                    {Array.isArray(errors?.operations) ? errors.operations.join(', ') : errors?.operations}
                                                </div>}
                                                    {pendingOperations.length === 0 && <div className="operations-warning">*Please select at least one operation.</div>}
                                                </>
                                            )}
                                            {activeStep === 2 && <Confirmation/>}
                                        </div>
                                        <div className="stepper-buttons">
                                            <button data-testid="step-cancel" className="fvp-button--outline fvp-button"
                                                    type="submit"
                                                    onClick={toggleModal}>
                                                <i className="fvp-left-chevron"/>Cancel
                                            </button>
                                            <div className="right-align">
                                                {(activeStep !== 0 && !isDelete) && (
                                                    <button className="fvp-button button-right-align" type="button"
                                                            onClick={handlePreviousClick}>
                                                        <i className="fvp-left-chevron"/> Previous
                                                    </button>
                                                )}
                                                {activeStep !== steps.length - 1 && (
                                                    <button data-testid="step-next"
                                                            className="fvp-button button-right-align" type="button"
                                                            disabled={isNextDisabled(isValid, errors)}
                                                            onClick={() => handleNextClick(values)}>
                                                        Next <i className="fvp-right-chevron"/>
                                                    </button>
                                                )}
                                                {activeStep === steps.length - 1 && (isDelete ?
                                                        <button data-testid="step-delete" className="fvp-button button-right-align" type="submit"
                                                                disabled={!isValid}>
                                                            Delete <i className="fvp-right-chevron"/>
                                                        </button> :
                                                        <button data-testid="step-submit" className="fvp-button button-right-align" type="submit"
                                                                disabled={!isValid}>
                                                            Confirm <i className="fvp-right-chevron"/>
                                                        </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default CFAdminBundlesForm;