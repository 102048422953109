import { Helmet } from "react-helmet-async";
import CFAdminLayout from "../../components/CFAdminLayout";
import { Outlet } from "react-router-dom";
import CFAdminHeader from "../../components/CFAdminHeader";
import CFAdminFooter from "../../components/CFAdminFooter";
import { AppProps } from "../../components/CFAdminNavigation/types";

function CFAdminLanding(user : AppProps) {

    return (
      <>
        <Helmet>
            <title>Consumer Facing Admin - Home Page</title>
        </Helmet>
        <CFAdminLayout
          header={<CFAdminHeader {...user} />}
          main={<Outlet />}
          footer={<CFAdminFooter/>}
        />
      </>
    );
  }
  
  export default CFAdminLanding;