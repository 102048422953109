import React from "react";

function CFAdminVinImportStatus ({ latestImportStatus, tableHeader, columnHeader, runningFlag }: Readonly<{
    latestImportStatus: any;
    tableHeader: string;
    columnHeader: string;
    runningFlag: string;
}>) {

    return (
        <div className="ext1">
            <table >
                <thead>
                <tr>
                    <th>{tableHeader}</th>
                </tr>
                </thead>
                <tbody>
                <tr className="">
                    <td><b>{columnHeader}</b></td>
                    <td><b>Current Progress</b></td>
                    <td><b>Current Status</b></td>
                    <td><b>Previous Status</b></td>
                </tr>
                <tr className="">
                    <td>{latestImportStatus?.[runningFlag]? 'Yes': 'No'}</td>
                    <td>{latestImportStatus?.currentProgress}</td>
                    <td>{latestImportStatus?.currentStatus}</td>
                    <td>{latestImportStatus?.previousStatus}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CFAdminVinImportStatus;