
export const handleResponseCloneAndCopyVehicle = (
    response: any,
    setIsConfirmOpen: (isOpen: boolean) => void,
    confirmSuccessMessage: (message: string) => void,
    confirmErrorMessage: (message: string) => void,
    successMessage: string,
    errorMessage: string
) => {
    if (response) {
        setIsConfirmOpen(false);
        confirmSuccessMessage(successMessage);
    } else {
        setIsConfirmOpen(false);
        confirmErrorMessage(errorMessage);
    }
};