import React, { ReactNode } from "react";
import './CFAdminModal.scss'
import CloseIcon from '../../assets/images/close.svg'

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  isAdd?: boolean;
  title: string;
}

function CFAdminModal(props: ModalType) {

  return (
    <>
      {props.isOpen && (
        <div className="modal-overlay">
          <div onClick={(e) => e.stopPropagation()} className="modal-box">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">{props.title}</h3>
                <img src={CloseIcon} alt="close" className="modal-close" onClick={props.toggle} title="Cancel"/>
              </div>
              <div className="modal-body">
                {props.children}
              </div>
             </div> 
          </div>
        </div>
      )}
    </>
  );
}

export default CFAdminModal;
