import { Formik } from 'formik'
import * as Yup from 'yup';
import {FVPHiddenInput, FVPSelect, FVPTextInput} from '../../components/formField';
import IntervalService from '../../services/IntervalService';
import React, {useState} from "react";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import {ActionMode} from "../../constants/ActionMode";


// Creating schema
const schema = Yup.object().shape({
    intervalId: Yup.number().min(0),
    startValue: Yup.number().min(1),
    endValue: Yup.number().min(0),
    intervalValue: Yup.number().min(1),
    startTrigger: Yup.number().min(0),
    endTrigger: Yup.number().min(0),
    intervalSortKey: Yup.number()
        .required("Sort Key is a required field")
        .min(1, "Sort key must be greater than 1"),
    intervalType: Yup.string()
        .oneOf(["Mileage", "IOLM"]),
    intervalDesc: Yup.string()
          .max(900, "Admin Desc is allowed maximum of 900 characters"),
  });

type OptionType = { description: string; code?: string };
type CFAdminIntervalFormProps = {
    intervalId: number,
    mode: ActionMode,
    startValue: number,
    endValue: number,
    intervalValue: number,
    startTrigger: number,
    endTrigger: number,
    intervalDesc: string,
    intervalType: string,
    intervalSortKey: number,
    toggleModal: any;
    refreshData: any;
    successMessage: any;
    errorMessage: any;
    isDelete: boolean;
};

function CFAdminIntervalForm({
 intervalId,
 mode,
 startValue,
 endValue,
 intervalValue,
 startTrigger,
 endTrigger,
 intervalDesc,
 intervalType,
 intervalSortKey,
  toggleModal,
  refreshData,
  successMessage,
  errorMessage,
  isDelete
}: CFAdminIntervalFormProps) {

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const intervalTypeCode: OptionType[] = [
        { code: 'Mileage', description: 'Mileage' },
        { code: 'IOLM', description: 'IOLM' },
    ];
  const saveInterval = (values: any) => {
    const data: any = {
        "startValue": Number(values.startValue),
        "endValue":Number(values.endValue),
        "intervalValue": Number(values.intervalValue),
        "startTrigger": Number(values.startTrigger),
        "endTrigger": Number(values.endTrigger),
        "intervalDesc": values.intervalDesc,
        "intervalType": values.intervalType,
        "intervalSortKey": Number(values.intervalSortKey),
    };

      IntervalService.create(data)
      .then(response => {
        successMessage(`Interval: ${response.data.intervalDesc} added successfully.`);
        refreshData();
        toggleModal();
      })
      .catch(e => {
        console.error(e);
        errorMessage("Error while adding Interval!.");
      });
  };

  const updateInterval = (values: any) => {
    const data = {
        "startValue": Number(values.startValue),
        "endValue":Number(values.endValue),
        "intervalValue": Number(values.intervalValue),
        "startTrigger": Number(values.startTrigger),
        "endTrigger": Number(values.endTrigger),
        "intervalDesc": values.intervalDesc,
        "intervalType": values.intervalType,
        "intervalSortKey": Number(values.intervalSortKey),
    };

      IntervalService.update(values.intervalId, data)
      .then(() => {
        successMessage(values.intervalDesc+" updated successfully.");
        refreshData();
        toggleModal();
      })
      .catch(e => {
            console.error(e);
          errorMessage("Error while updating Interval!.");
      });
  };



    const deleteInterval = () => {

        if (intervalId) {

            IntervalService.delete(intervalId)
                .then(() => {
                    successMessage(`Interval: ${intervalDesc} deleted successfully.`);
                    refreshData();
                    toggleModal();
                })
                .catch(e => {
                    errorMessage("Error while deleting Interval!");
                });
        } else {
            errorMessage("Error while deleting Interval!");
        }
    };

    return (

        <>
        <Formik
        validationSchema={schema}
        initialValues={{ intervalId: intervalId, mode: mode, startValue: startValue, endValue: endValue, intervalValue: intervalValue,
            startTrigger: startTrigger, endTrigger:endTrigger, intervalDesc: intervalDesc,
            intervalType: intervalType, intervalSortKey: intervalSortKey}}
        onSubmit={(values) => {
            if(isDelete){
                setIsDeleteConfirmOpen(true);
            } else {
                if (intervalId !== undefined && intervalId > 0) {
                    updateInterval(values);
                } else {
                    saveInterval(values);
                }
            }
        }}
      >
        {({
          values,
          handleSubmit,
          isValid,
          dirty
        }) => (
          <div className="fvp-form">
            <div >
              {/* Passing handleSubmit parameter tohtml form onSubmit property */}
              <form data-testid="SubmitForm" noValidate onSubmit={handleSubmit}>
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                <div className="modal-body">
                    <div className="fvp-form__group">
                        <div className="fvp-form__subgroup">
                            <FVPTextInput label="Start Value:" name="startValue" required readOnly={isDelete}/>
                            <FVPTextInput label="End Value:" name="endValue" required readOnly={isDelete}/>
                        </div>
                        <div className="fvp-form__subgroup">
                            <FVPTextInput label="Interval Value:" name="intervalValue" required readOnly={isDelete}/>
                        </div>
                        <div className="fvp-form__subgroup">
                            <FVPTextInput label="Start Trigger:" name="startTrigger" required readOnly={isDelete}/>
                            <FVPTextInput label="End Trigger:" name="endTrigger" required readOnly={isDelete}/>
                        </div>
                        <div className="fvp-form__subgroup">
                            <FVPHiddenInput name="intervalId" />
                            <FVPTextInput label="Interval Desc:" name="intervalDesc" required readOnly={isDelete}/>
                        </div>
                        <div className="fvp-form__subgroup">
                            <FVPTextInput label="Sort Key:" name="intervalSortKey" required readOnly={isDelete}/>
                            <FVPSelect
                                label="Interval Type:"
                                name="intervalType"
                                options={intervalTypeCode}
                                required
                                disabled={isDelete}
                            />
                        </div>




                  </div>
                </div>
                <div className="modal-footer button-frame footer-buttons">
                  {/*
                  consider a special class for a collection of buttons; their
                  wrapping should probably be done in a special way (submit to the
                  right when laid out in a row, on top when stacked vertically.)
                  */}
                  <button  data-testid="Cancel"
                    className="fvp-button--outline"
                    onClick={toggleModal}
                  >
                    <i className="fvp-left-chevron" />
                    Cancel
                  </button>

                    {isDelete?
                        <button
                            data-testid="Delete"
                            className="fvp-button-danger--outline button-right-align"
                            type="submit"
                        >
                            Delete
                            <i className="fvp-right-chevron" />
                        </button> : <button
                            data-testid="Save"
                            className="fvp-button button-right-align"
                            type="submit"
                            disabled={!(dirty && isValid)}

                        >
                            {values.intervalId === undefined || values.intervalId === 0 ? 'Save' : 'Update'}
                            <i className="fvp-right-chevron" />
                        </button>}
                </div>
            </form>
            </div >
          </div>
        )}
      </Formik>
            <CFAdminConfirmDialog
                isOpen={isDeleteConfirmOpen}
                onClose={()=>setIsDeleteConfirmOpen(false)}
                onConfirm={deleteInterval}
                title={"Delete Interval: " + intervalDesc}
                text="Are you sure you want to delete this Interval?"
                overlayClassName="delete-modal-overlay"
            />
      </>
    );
  }

export default CFAdminIntervalForm;
