import React, {useEffect} from "react";
import {BundleOperation} from "./index";

type BundlesConfirmationProps = {
    bundleId: number,
    bundleCode: string,
    bundleDescription: string,
    operations: BundleOperation[],
    isDelete: boolean,
    handleNext: (nextStep: number) => void;
    activeStep: number;
    nextStep: number;
}

// Function to sort operations
const sortOperations = (operations: BundleOperation[]) => {
    return operations.sort((a, b) => a.sortKey - b.sortKey);
};

const CFAdminBundleConfirmation = ({
                                       bundleId,
                                       bundleCode,
                                       bundleDescription,
                                       operations,
                                       isDelete,
                                       handleNext,
                                       activeStep,
                                       nextStep
                                   }: BundlesConfirmationProps) => {

    const bundleAction = bundleId ? "Update" : "New";
    const action = isDelete ? "Delete" : bundleAction;
    const sortedOperations = sortOperations(operations);

    useEffect(() => {
        if (activeStep === 2 && nextStep === 1) {
            handleNext(1);
        }
    }, [activeStep, nextStep, handleNext]);
    return (
        <div className="bundle-info">
            <h2>Bundle Information</h2>
            <hr/>
            <p><strong>Action:</strong> {action}</p>
            <p><strong>Bundle Code:</strong> {bundleCode}</p>
            <p><strong>Bundle Description:</strong> {bundleDescription}</p>
            <br/>
            <strong>Operations:</strong>
            <ul>
                {sortedOperations.map((operation) => (
                    <li key={operation.operationCode}>
                        <strong>Code: {operation.operationCode}</strong> - {operation.operationDescription}
                    </li>
                ))}
            </ul>

        </div>
    );
};

export default CFAdminBundleConfirmation;