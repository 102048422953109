import React from "react";

const Tab = ({ label, onClick, isActive } :
                 {label: any, onClick: any, isActive : boolean}) => (
    <div
        className={`tab ${isActive ? "active" : ""}`}
        onClick={onClick}
    >
        {label}
    </div>
);

export default Tab;