import { authHeader } from "../util/auth-header";
import http from "../util/http-common";

export class SysMaintainenceService {
  static getAllCountries() {
    return http.get("/cfadminsysmaintain/api/v1/countries",{ headers: authHeader()});
  }

  static getAllLanguages() {
    return http.get("/cfadminsysmaintain/api/v1/languages",{ headers: authHeader()});
  }

  static getAllCountryLanguages() {
    return http.get("/cfadminsysmaintain/api/v1/countries-languages",{ headers: authHeader()});
  }

}

export default SysMaintainenceService;