import './App.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useEffect } from 'react';
import {AuthService} from './services/AuthService';
import AppRoutes from './routes';
import Loader from './components/CFAdminLoader';
import { useNavigate } from 'react-router-dom';
import {retrieveUrlParams, retrieveUrl, clearTokens, isLogged, setUserData, setUserTokens, is401Error } from "./util/auth-validation";
import { AxiosHeaders } from 'axios';
import ReactDOM from "react-dom";
import PleaseWait from "./components/CFAdminWait";

// Create a div to render the PleaseWait component
const pleaseWaitDiv = document.createElement('div');
document.body.appendChild(pleaseWaitDiv);
ReactDOM.render(<PleaseWait/>, pleaseWaitDiv);

function App() {

  const [load, setLoad] = React.useState(true);
  const [user, setUser] = React.useState({ssoUserDetails:'',promoteFlag:'',canBatchAccess: '',canSystemMaintenance:'',
    canDataPublish: '', canGSMOperations: '', canTestClients: '', canDataAdmin: '', canVinImport: '', canVinValidate: '',  canVinPublish: '', authorised: ''});
  const navigate = useNavigate();

    useEffect(() => {
        if(window.location.pathname === '/callback'){
            retrieveUrlParams().then(
                (code) => {
                    clearTokens().then(res => {
                        getUserToken(code)
                    });
                });
        }else{
            isLogged().then(isValid =>{
                if(isValid){getUserDetails();}else{clearTokens().then(res => {retrieveUrl();});}
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

// Helper function to update user data 
const updateUserData = async (data: any) => {
  await setUserData(data);
  setUser({
    ...user,
    ssoUserDetails: data.ssoUserDetails,
    canBatchAccess: data.canBatchAccess,
    canSystemMaintenance: data.canSystemMaintenance,
    canDataAdmin: data.canDataAdmin,
    canDataPublish: data.canDataPublish,
    canGSMOperations: data.canGSMOperations,
    canTestClients: data.canTestClients,
    canVinImport: data.canVinImport,
    canVinValidate: data.canVinValidate,
    canVinPublish: data.canVinPublish,
    authorised: data.authorised,
  });
  setLoad(false);
  if (data.authorised) {
    console.log("Navigating to Home - ");
    navigate('/');
  } else {
    console.log("Navigating to Access Restrict - ");
    navigate('/accessrestrict');
  }
};

  const getUserDetails = async () => {
    setLoad(true);
    AuthService.getUserDetails()
        .then(response => {

          if (response.data) {
            console.log("setting user - ");
            updateUserData(response.data);
          }
        })
        .catch(e => {
          console.log(e);
          is401Error(e).then(res => {
            getRefreshToken();
          });
        });
  };

  const getRefreshToken = async () => {
    setLoad(true);
    AuthService.getRefreshToken()
        .then(response => {

          if(response.data.errorCode != null){
            clearTokens().then(res => {
              retrieveUrl();
            });
          }else{
            setTokens(response);
          }
        })
        .catch(e => {
          console.log(e);
          is401Error(e).then(res => {
            clearTokens().then(res => {
              retrieveUrl();
            });
          });
        });
  };

  const setTokens = async (response: any) => {
    if(response.headers){
      const headers = response.headers;
      if (headers instanceof AxiosHeaders) {
        const awsmodtoken = headers.get("X-Access-Token");
        //console.log("awsmodtoken - "+awsmodtoken);
        const rwsmodtoken = headers.get("X-Refresh-Token");
        //console.log("rwsmodtoken - "+rwsmodtoken);
        setUserTokens(awsmodtoken,rwsmodtoken ).then( res => {
          if (response.data) {
            //console.log("setting user - ");
            updateUserData(response.data);
          }
        });
      }
    }
  };

  const getUserToken = async (code: any) => {
    setLoad(true);
    AuthService.getUserToken(code)
        .then(response => {

          if(response.data.errorCode != null){
            return;
          }
          setTokens(response);
        })
        .catch(e => {
          console.log(e);
        });
  };

  return (

      <HelmetProvider>
        <Helmet>
          <title>Maintenance Schedule Pricing</title>
        </Helmet>
        {load ? <Loader message={'User Authentication'}/>  : <AppRoutes {...user}  />}

      </HelmetProvider>
  );
}

export default App;
