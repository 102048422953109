import { Helmet } from "react-helmet-async";
import React from "react";

function CFAdminCallback() {

    return (
      <>
        <Helmet>
            <title>Consumer Facing Admin - Callback</title>
        </Helmet>
      </>
    );
}

export default CFAdminCallback;