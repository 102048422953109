import { useState } from "react";
import "./Accordion.scss";
import { AccordionData } from './types';
import AccordionItem from "./AccordionItem";

function Accordion({ menuClose, items }: Readonly<{ menuClose: () => void; items: Array<AccordionData> }>) {
  const [currentId, setCurrentId] = useState(-1);
  const btnOnClick = (id: number) => {
    setCurrentId((currentValue) => (currentValue !== id ? id : -1));
  };
  return ( <ul className="accordion">
  {items.map(item => (
    <AccordionItem 
          key={item.id}
          data={item}
          isOpen={item.id === currentId}
          menuClose={menuClose}
          btnOnClick={() => btnOnClick(item.id)} />
  ))}
</ul>
)}


export default Accordion;