import './CFAdminMakes.scss';
import {Formik} from 'formik'
import * as Yup from 'yup';
import {FVPHiddenInput, FVPSelect, FVPTextInput} from '../../components/formField';
import MakeService from '../../services/MakeService';
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import React, {useState} from "react";
import {ActionMode} from "../../constants/ActionMode";

// Creating schema
const schema = Yup.object().shape({
  makeDesc: Yup.string()
      .required("Make description is a required field")
      .min(1, "Make description must be at least 3 characters")
      .max(10,"Make desc must be less than 10 characters"),
  countryId: Yup.number().required()
  });

type CFAdminMakesProps = {
    id?: number;
    mode?: ActionMode;
    description?: string;
    country: string;
    language: string;
    toggleModal: any;
    refreshData: any;
    successMessage: any;
    errorMessage: any;
};

function CFAdminMakesForm({
  id,
  mode,
  description,
  toggleModal,
  refreshData,
  successMessage,
  errorMessage
}: Readonly<CFAdminMakesProps>) {

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const saveOperation = (values: any) => {
      const data = {
          "description": values.makeDesc,
      };

      MakeService.create(data)
      .then(() => {
        successMessage(`Make: ${values.makeDesc} added successfully.`);
        refreshData();
        toggleModal();
      })
      .catch(e => {
        errorMessage(e.response.data.errorMessage);
        console.log(e);
      });
  };

  type optionType = { description: string; code?: string };

  const countryOptions: optionType[] = [
    { code: '1', description: 'United States' },
  ];

  const updateOperation = (values: any) => {
      const data = {
          "description": values.makeDesc
      };

      MakeService.update(values.id, data)
      .then(() => {
        successMessage(`Make: ${values.makeDesc} updated successfully.`);
        refreshData();
        toggleModal();
      })
      .catch(e => {
        errorMessage("Error while updating Operation Code!.");
        console.log(e);
      });
  };


const deleteOperation = () => {

    MakeService.delete(id)
        .then(() => {
            successMessage(`Successfully deleted make: ${description}`);
            refreshData();
            toggleModal();
        })
        .catch(e => {
            errorMessage(`Error while deleting make: ${description}`);
            console.log(e);
        });
}

  
    return (
        <>
        <Formik
            validationSchema={schema}
            initialValues={{id: id, makeDesc: description, countryId: 1,  }}
            onSubmit={(values) => {
                if (mode && mode === ActionMode.Edit && id !== undefined && id > 0) {
                    updateOperation(values);
                } else if (mode && mode === ActionMode.Add) {
                    saveOperation(values);
                } else if (mode && mode === ActionMode.Delete) {
                    setIsDeleteConfirmOpen(true);
                } else {
                    console.error(`Unknown mode: ${mode}`, mode);
                }
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isValid,
                  dirty
              }) => (
                <div className="fvp-form">
                    <div>
                        {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                        <form data-testid="SubmitForm" noValidate onSubmit={handleSubmit}>
                            {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                            <div className="modal-body">
                                <div className="fvp-form__group">
                                    <FVPHiddenInput name="makeId"/>
                                    <FVPTextInput label="Description:" name="makeDesc" required={mode !== ActionMode.Delete} disabled={mode === ActionMode.Delete}/>
                                    <FVPSelect
                                        label="Country"
                                        name="countryId"
                                        options={countryOptions}
                                        required={mode !== ActionMode.Delete}
                                        disabled={mode === ActionMode.Delete}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer button-frame">
                                {/*
                  consider a special class for a collection of buttons; their
                  wrapping should probably be done in a special way (submit to the
                  right when laid out in a row, on top when stacked vertically.)
                  */}
                                <button
                                    data-testid="Cancel"
                                    className="fvp-button--outline"
                                    onClick={toggleModal}
                                ><i className="fvp-left-chevron"/>Cancel
                                </button>
                                {mode=== ActionMode.Delete?
                                    <button
                                        data-testid="Delete"
                                        className="fvp-button-danger--outline"
                                        type="submit"
                                    >
                                        Delete
                                        <i className="fvp-right-chevron" />
                                    </button> : <button
                                        data-testid="Save"
                                        className="fvp-button"
                                        type="submit"
                                        disabled={!(dirty && isValid)}
                                    >
                                        Save
                                        <i className="fvp-right-chevron" />
                                    </button>}
                            </div>
                        </form>
                    </div>

                </div>
            )}
        </Formik>
    <CFAdminConfirmDialog
        isOpen={isDeleteConfirmOpen}
        onClose={()=>setIsDeleteConfirmOpen(false)}
        onConfirm={deleteOperation}
        title={"Delete Make: " + description}
        text="Are you sure you want to delete this Make?"
    />
        </>
    );
  }

export default CFAdminMakesForm;