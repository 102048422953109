import React, {useEffect, useRef, useState} from 'react';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminModal from "../../components/CFAdminModal";
import './CFAdminMakes.scss'
import CFAdminMakesForm from './CFAdminMakesForm';
import MakeService from '../../services/MakeService';
import CFAdminNotification from '../../components/CFAdminNotification';
import {ActionMode} from "../../constants/ActionMode";
import ActionButtons from "../../util/renderActionButtons";


function CFAdminMakes() {
    const childCompRef = useRef<any>();
    const [data, setData] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: 0,
        mode: ActionMode.Add,
        description: "",
        country: "",
        language: "",
        userKey:"",
        modified:"",
        usedFlag: false
    });
    const [actionText, setActionText] = useState("Add Make");


    const columns = [
        {
            Header: "Make Id",
            accessor: 'id'
        },
        {
            Header: "Make",
            accessor: 'description'
        },
        {
            Header: "Country",
            accessor: 'country'
        },
        {
            Header: "Language",
            accessor: 'language'
        },
        {
            Header: "Last Modified By",
            accessor: 'userKey'
        },
        {
            Header: "Last Modified Date",
            accessor: 'modified'
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                const {row} = props;
                const inUse = row.original.usedFlag;
                return (
                    <ActionButtons
                        props={props}
                        handleChange={handleEdit}
                        handleDelete={handleDelete}
                        disabled={inUse}
                    />
                );
            }
        }
    ];

    const handleEdit = (event: any) => {
        setActionText("Edit Make");
        setInitialValues({
            ...initialValues,
            id: event.originalRow.id,
            mode: ActionMode.Edit,
            description: event.originalRow.description,
            country: event.originalRow.country,
            language: event.originalRow.language,
            usedFlag: event.originalRow.usedFlag
        });
        setIsOpen(!isOpen);
    }

    const handleDelete = (event: any) => {
        setActionText("Delete Make")
        setInitialValues({
            ...initialValues,
            id: event.originalRow.id,
            mode: ActionMode.Delete,
            description: event.originalRow.description,
            country: event.originalRow.country,
            language: event.originalRow.language,
            usedFlag: event.originalRow.usedFlag
        });
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        retrieveOperations();
    }, []);


    const retrieveOperations = async () => {
        MakeService.getAll()
            .then(response => {
                setData(response.data.makesResponseModel);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = (isAdd?:boolean) => {
        setInitialValues({
            ...initialValues,
            id: 0,
            description: '',
            country: '',
            language: '',
            usedFlag: false,
            mode: isAdd ? ActionMode.Add : ActionMode.Edit
        });
        isAdd && setActionText("Add Make");
        setIsOpen(!isOpen);
    }

    return (

        <div className="make-component">
            <h2 className="fomo-type--title">Manage Makes</h2>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={true}
                title={actionText}
            >
                <CFAdminMakesForm {...initialValues}
                                  toggleModal={toggleModal}
                                  refreshData={retrieveOperations}
                                  successMessage={confirmSuccessMessage}
                                  errorMessage={confirmErrorMessage}/>
            </CFAdminModal>

            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={toggleModal}
                textModal={'Add Make'}
            />

            <CFAdminNotification ref={childCompRef}/>
        </div>
    );
}

export default CFAdminMakes;