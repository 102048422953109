import React, {useEffect, useState} from "react";
import CFAdminDataTable from "../../components/CFAdminDataTable";
import SysMaintainenceService from "../../services/SysMaintainenceService";
import {ExpandCollapseCell, ExpandCollapseHeader} from "../../components/CFAdminDataTable/ExpandCollapseComponents";


function CFAdminCountryLanguages() {

    const [data, setData] = useState([]);
    const columns =  [
        {
            id: 'Id', // Make sure it has an ID
            Header: ExpandCollapseHeader,
            Cell: ExpandCollapseCell,
        },
        {
            Header : "Description",
            accessor : 'admin'
        },
        {
            Header : "ISO Code",
            accessor : 'isoCode'
        },
        {
            Header : "Last Modified By",
            accessor : 'userKey'
        },
        {
            Header : "Last Modified Date",
            accessor : 'modified'
        },
    ];

    useEffect(() => {
        retrieveCountryLanguages();
    }, []);



    const retrieveCountryLanguages = async () => {
       SysMaintainenceService.getAllCountryLanguages()
            .then(response => {
                setData(response.data.countriesLanguagesResponseModel);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <>
          <h2 className="fomo-type--title">Manage Country Languages</h2>
            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={''}
                textModal={'none'}
            />
        </>
      );
  }
  
  export default CFAdminCountryLanguages;