import http from "../util/http-common";
import {authHeader} from "../util/auth-header";

export class DataPublishService {
    static getAll() {
        return http.get("/cfadminvinadmin/api/v1/publish", {headers: authHeader()});
    }

    static publish() {
        return http.post("/cfadminvinadmin/api/v1/publish", {}, {headers: authHeader()});
    }
}

export default DataPublishService;