
import CFAdminBackground from './../CFAdminBackground';
import './CFAdminLayout.scss';

type LayoutProps = {
    header?: React.ReactNode;
    main: React.ReactNode;
    sidebar?: React.ReactNode;
    footer: React.ReactNode;
    backgroundColor?: string;
};

function CFAdminLayout({
    header,
    main,
    footer,
    backgroundColor,
}: LayoutProps) {

  return (
    <CFAdminBackground color={backgroundColor}>
        {header}
         <main className="main"> 
          {main}
         </main>
          {footer}   
    </CFAdminBackground>
  );
}

export default CFAdminLayout;
