import BaseService from "./BaseService";

export class OperationService extends BaseService {
  static getAll() {
    return this.get("/cfadminsysmaintain/api/v1/operations");
  }

  static create(data: any) {
    return this.post("/cfadminsysmaintain/api/v1/operations", data);
  }

  static update(id: any, data: any) {
    return this.put("/cfadminsysmaintain/api/v1/operations", id, data);
  }

  static delete(id: any) {
    return super.delete("/cfadminsysmaintain/api/v1/operations", id);
  }
}

export default OperationService;