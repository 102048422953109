import { useState } from 'react';

type OptionType = { description: string; code?: string, make?: string };

const useVehicleState = () => {
        const [yearsOptions, setYearsOptions] = useState<OptionType[]>([]);
        const [toYearsOptions, setToYearsOptions] = useState<OptionType[]>([]);
        const [fromModelsOptions, setFromModelsOptions] = useState<OptionType[]>([]);
        const [toModelsOptions, setToModelsOptions] = useState<OptionType[]>([]);
        const [fromModelSelectValue, setFromModelSelectValue] = useState('');
        const [toModelSelectValue, setToModelSelectValue] = useState('');
        const [fromYearSelectValue, setFromYearSelectValue] = useState('');
        const [toYearSelectValue, setToYearSelectValue] = useState('');
        const [isFromYearSelected, setIsFromYearSelected] = useState(false);
        const [isFromModelSelected, setIsFromModelSelected] = useState(false);
        const [isToYearSelected, setIsToYearSelected] = useState(false);
        const [modelYearResponsesList, setModelYearResponsesList] = useState([]);
        const [isConfirmOpen, setIsConfirmOpen] = useState(false);
        const [confirmDescription, setConfirmDescription] = useState('');
        const [fromColumnValues, setFromColumnValues] = useState<any>([]);
        const [fromDataValues, setFromDataValues] = useState<any>([]);
        const [fromTotalRecords, setFromTotalRecords] = useState<number>(0);
        const [toColumnValues, setToColumnValues] = useState<any>([]);
        const [toDataValues, setToDataValues] = useState<any>([]);
        const [toTotalRecords, setToTotalRecords] = useState<number>(0);

        return {
            yearsOptions, setYearsOptions,
            toYearsOptions, setToYearsOptions,
            fromModelsOptions, setFromModelsOptions,
            toModelsOptions, setToModelsOptions,
            fromModelSelectValue, setFromModelSelectValue,
            toModelSelectValue, setToModelSelectValue,
            fromYearSelectValue, setFromYearSelectValue,
            toYearSelectValue, setToYearSelectValue,
            isFromYearSelected, setIsFromYearSelected,
            isFromModelSelected, setIsFromModelSelected,
            isToYearSelected, setIsToYearSelected,
            modelYearResponsesList, setModelYearResponsesList,
            isConfirmOpen, setIsConfirmOpen,
            confirmDescription, setConfirmDescription,
            fromColumnValues, setFromColumnValues,
            fromDataValues, setFromDataValues,
            fromTotalRecords, setFromTotalRecords,
            toColumnValues, setToColumnValues,
            toDataValues, setToDataValues,
            toTotalRecords, setToTotalRecords
        };
};

export default useVehicleState;