import React, {useState} from "react";
import CFAdminDataTable from "../../components/CFAdminDataTable";

function CFAdminVehicleConfigDetails ({fromColumnValues, fromDataValues, fromTotalRecords, toColumnValues, toDataValues, toTotalRecords, handleConfirmSubmit, fromColumnDesc, toColumnDesc} : any) {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index: any) => {
        setToggleState(index);
    };

    return (
        <>
            <div className="container-tab">
                <div className="bloc-tabs">
                    <button
                        data-testid="submit-tab1-next"
                        className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(1)}
                    >
                        From : &nbsp; <b> {fromColumnDesc} </b>
                    </button>
                    <button
                        data-testid="submit-tab2-next"
                        className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(2)}
                    >
                        To : &nbsp; <b> {toColumnDesc} </b>
                    </button>
                </div>
                <div className="content-tabs">
                    <div
                        className={toggleState === 1 ? "content  active-content" : "content"}
                    >
                        <div className="ext2">
                            * {fromTotalRecords} vehicle configurations were found for the vehicle (<b>{fromColumnDesc}</b>) listed below
                        </div>
                        <>
                            <CFAdminDataTable
                                columns={fromColumnValues}
                                data={fromDataValues}
                                textModal={''}
                                showFilter={false}
                                showAdd={false}
                                showPagination={false}
                                pageSize={1000}
                            />
                        </>
                    </div>

                    <div
                        className={toggleState === 2 ? "content  active-content" : "content"}
                    >
                        <div className="ext2">
                            * {toTotalRecords} vehicle configurations were found for the vehicle (<b>{toColumnDesc}</b>) listed below
                        </div>
                        <>
                            <CFAdminDataTable
                                columns={toColumnValues}
                                data={toDataValues}
                                textModal={''}
                                showFilter={false}
                                showAdd={false}
                                showPagination={false}
                                pageSize={1000}
                            />
                        </>
                    </div>
                </div>
            </div>

            <div className="right-align">
                <button data-testid="submit-confirm"
                        className="fvp-button button-right-align" type="button"
                        onClick={() => handleConfirmSubmit()}>
                    Confirm
                </button>
            </div>
        </>
    )
}

export default CFAdminVehicleConfigDetails;