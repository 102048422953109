import BaseService from "./BaseService";

export class IntervalService extends BaseService {
  static getAll() {
    return this.get("/cfadminsysmaintain/api/v1/intervals");
  }

  static create(data: any) {
    return this.post("/cfadminsysmaintain/api/v1/intervals", data);
  }

  static update(id: any, data: any) {
    return this.put("/cfadminsysmaintain/api/v1/intervals", id, data);
  }

  static delete(id: any) {
    return super.delete("/cfadminsysmaintain/api/v1/intervals", id);
  }
}

export default IntervalService;