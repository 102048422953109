import { Formik } from 'formik'
import * as Yup from 'yup';
import { FVPHiddenInput, FVPTextInput, FVPTextInputWithSize } from '../../components/formField';
import OperationService from '../../services/OperationService';
import CFAdminTabs from "../../components/CFAdminTabs";
import {CountryLanguageEnum} from "../../constants/CountryLanguageEnum";
import React, {useState} from "react";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";

let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

// Creating schema
const schema = Yup.object().shape({
  opId: Yup.number(),
  adminDescId: Yup.number(),
  adminUsSpaDescId: Yup.number(),
  adminCanEngDescId: Yup.number(),
  adminCanFrDescId: Yup.number(),
  opCode: Yup.string()
      .required("opCode is a required field")
      .min(1, "opCode must be at least 3 characters")
      .max(10,"opCode must be at least 3 characters"),
      avgLts: Yup.number()
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      ).required("Average Lts is a required field"),
      sortkey: Yup.number()
        .required("Sort Key is a required field")
        .min(1, "Password must be at least 1 characters"),
      adminDesc: Yup.string()
        .required("Admin Desc is a required field")
        .max(900, "Admin Desc is allowed maximum of 900 characters"),
      adminUsSpaDesc: Yup.string()
          .max(900, "Admin Desc (US - Spanish) is allowed maximum of 900 characters"),
      adminCanEngDesc: Yup.string()
          .max(900, "Admin Desc (Canada - English) is allowed maximum of 900 characters"),
      adminCanFrDesc: Yup.string()
          .max(900, "Admin Desc (Canada - French) is allowed maximum of 900 characters")
  });

type CFAdminOperationFormProps = {
    opId?: number;
    opCode?: string;
    avgLts: string;
    sortkey?: string;
    adminDesc: string;
    adminUsSpaDesc: string;
    adminCanEngDesc: string;
    adminCanFrDesc: string;
    adminDescId?: number;
    adminUsSpaDescId?: number;
    adminCanEngDescId?: number;
    adminCanFrDescId?: number;
    toggleModal: any;
    refreshData: any;
    successMessage: any;
    errorMessage: any;
    isDelete: boolean;
};

function CFAdminOperationForm({
  opId,
  opCode,
  avgLts,
  sortkey,
  adminDesc,adminDescId,
  adminUsSpaDesc,adminUsSpaDescId,
  adminCanEngDesc,adminCanEngDescId,
  adminCanFrDesc,adminCanFrDescId,
  toggleModal,
  refreshData,
  successMessage,
  errorMessage,
  isDelete
}: CFAdminOperationFormProps) {

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const saveOperation = (values: any) => {
        const data: any = {
            "operationCode": values.opCode,
            "operationLanguages" : [
                {
                    "countryLanguageId": CountryLanguageEnum.US_ENG,
                    "description": values.adminDesc,
                },
                {
                    "countryLanguageId":  CountryLanguageEnum.CAN_ENG,
                    "description": values.adminCanEngDesc,
                },
                {
                    "countryLanguageId": CountryLanguageEnum.CAN_FRA,
                    "description": values.adminCanFrDesc,
                },
                {
                    "countryLanguageId": CountryLanguageEnum.US_SPA,
                    "description": values.adminUsSpaDesc,
                }
            ],
            "avgLTS": Number(values.avgLts),
            "sortKey": Number(values.sortkey)
        };

    OperationService.create(data)
        .then(() => {
        successMessage(values.opCode+" added successfully.");
        refreshData();
        toggleModal();
      })
      .catch(function (e)  {
        errorMessage("Error: " + e.response.data.errorMessage);
      });
  };

  const updateOperation = (values: any) => {
    const data = {
      "operationCode": values.opCode,
      "operationLanguages" : [{
        "operationLanguageTextId" : values.adminDescId,
        "countryLanguageId" : CountryLanguageEnum.US_ENG,
        "description" : values.adminDesc
      },{
      "operationLanguageTextId" : values.adminCanEngDescId,
      "countryLanguageId" : CountryLanguageEnum.CAN_ENG,
        "description" : values.adminCanEngDesc,
      },{
      "operationLanguageTextId" : values.adminCanFrDescId,
      "countryLanguageId" : CountryLanguageEnum.CAN_FRA,
        "description" : values.adminCanFrDesc,
      },{
      "operationLanguageTextId" : values.adminUsSpaDescId,
      "countryLanguageId" : CountryLanguageEnum.US_SPA,
        "description" : values.adminUsSpaDesc
      }],
      "avgLTS": Number(values.avgLts),
      "sortKey": Number(values.sortkey),
    };

    OperationService.update(values.opId, data)
      .then(() => {
        successMessage(values.opCode+" updated successfully.");
        refreshData();
        toggleModal();
      })
        .catch(function (e)  {
            errorMessage("Error: " + e.response.data.errorMessage);
            console.error("Error message is " + JSON.stringify(e));
        });
  };

    const deleteOperation = () => {

        if (opId) {

            OperationService.delete(opId)
                .then(() => {
                    successMessage(`Operation: ${opCode} deleted successfully.`);
                    refreshData();
                    toggleModal();
                })
                .catch(e => {
                    errorMessage("Error while deleting Operation!");
                    console.error(e);
                });
        } else {
            errorMessage("Error while deleting Operation!");
        }
    };

  const tabData = [
    { label: "USA" ,
      content: <div><FVPHiddenInput name="adminDescId" /><FVPTextInputWithSize label="Description(English):" size={900}  name="adminDesc" required readOnly={isDelete}/>
        <FVPHiddenInput name="adminUsSpaDescId" /><FVPTextInputWithSize label="Description(Spanish):" size={900}  name="adminUsSpaDesc" readOnly={isDelete}/>
        </div>
    },
    { label: "CANADA",
      content: <div><FVPHiddenInput name="adminCanEngDescId" /><FVPTextInputWithSize label="Description(English):" size={900}  name="adminCanEngDesc"  readOnly={isDelete}/>
        <FVPHiddenInput name="adminCanFrDescId" /><FVPTextInputWithSize label="Description(French):" size={900}  name="adminCanFrDesc"  readOnly={isDelete}/>
      </div>
    },
  ];
  
    return (
        <>
        <Formik
        validationSchema={schema}
        initialValues={{ opId: opId, opCode: opCode, avgLts: avgLts, sortkey: sortkey, adminDesc: adminDesc,
          adminUsSpaDesc: adminUsSpaDesc, adminCanEngDesc: adminCanEngDesc, adminCanFrDesc: adminCanFrDesc,
            adminDescId: adminDescId,adminUsSpaDescId: adminUsSpaDescId, adminCanEngDescId: adminCanEngDescId,
            adminCanFrDescId: adminCanFrDescId}}
        onSubmit={(values) => {
            if(isDelete){
                setIsDeleteConfirmOpen(true);
            } else {
                if (opId !== undefined && opId > 0) {
                    updateOperation(values);
                } else {
                    saveOperation(values);
                }
            }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          dirty
        }) => (
          <div className="fvp-form">
            <div className="wide-data-table">
              {/* Passing handleSubmit parameter tohtml form onSubmit property */}
              <form data-testid="SubmitForm" noValidate onSubmit={handleSubmit}>
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                <div className="modal-body">
                    <div className="fvp-form__group">
                    <div className="fvp-form__subgroup">
                        <FVPHiddenInput name="opId" />
                        <FVPTextInput label="Operation Code:" name="opCode" required readOnly={isDelete}/>
                        <FVPTextInput label="Average LTS:" name="avgLts" required readOnly={isDelete}/>
                        <FVPTextInput label="Sort Key:" name="sortkey" required readOnly={isDelete}/>
                    </div>
                        <CFAdminTabs tabs={tabData} />
                  </div>
                </div>
                <div className="modal-footer button-frame">
                  {/*
                  consider a special class for a collection of buttons; their
                  wrapping should probably be done in a special way (submit to the
                  right when laid out in a row, on top when stacked vertically.)
                  */}
                  <button  data-testid="Cancel"
                    className="fvp-button--outline"
                    onClick={toggleModal}
                  >
                    <i className="fvp-left-chevron" />
                    Cancel
                  </button>

                    {isDelete?
                        <button
                            data-testid="Delete"
                            className="fvp-button-danger--outline"
                            type="submit"
                        >
                            Delete
                            <i className="fvp-right-chevron" />
                        </button> : <button
                            data-testid="Save"
                            className="fvp-button"
                            type="submit"
                            disabled={!(dirty && isValid)}

                        >
                            {values.opId === undefined || values.opId === 0 ? 'Save' : 'Update'}
                            <i className="fvp-right-chevron" />
                        </button>}
                </div>
            </form>
            </div >
          </div>
        )}
      </Formik>
            <CFAdminConfirmDialog
                isOpen={isDeleteConfirmOpen}
                onClose={()=>setIsDeleteConfirmOpen(false)}
                onConfirm={deleteOperation}
                title={"Delete Operation: " + opCode}
                text="Are you sure you want to delete this Operation?"
            />
      </>
    );
  }

export default CFAdminOperationForm;