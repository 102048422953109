// src/components/ActionButtons.tsx
import React from 'react';
import {EditButton, DeleteButton} from "../components/CFAdminDataTable/ActionsButtonComponents";

interface ActionButtonsProps {
    props: any;
    handleChange: Function;
    handleDelete: Function;
    disabled?: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ props, handleChange, handleDelete, disabled = false }) => {
    return (
        <div className="cell-buttons">
            <EditButton {...props} handleChange={handleChange} />
            <DeleteButton {...props} handleDelete={handleDelete} disabled={disabled} />
        </div>
    );
};

export default ActionButtons;