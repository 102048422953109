import React, { useImperativeHandle } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CFAdminNotification.scss'

const CFAdminNotification = React.forwardRef((props, ref) => {
 
  useImperativeHandle(ref, () => ({
    successMessage (msg: any) {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-message'
        });
    },
   failureMessage(msg: any){
      toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-error-message'
      });
    },
  }))
  return (
    <>
      <ToastContainer />
    </>
  );
});

export default CFAdminNotification;
