import React, {useEffect, useState} from 'react';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import RecommendationService from "../../services/RecommendationService";


function CFAdminIolmIntervals() {

    const [data, setData] = useState([]);

    const columns = [
        {
            Header: "Config Id",
            accessor: 'vehicleId'
        },
        {
            Header: "Year",
            accessor: 'year'
        },
        {
            Header: "Make",
            accessor: 'make'
        },
        {
            Header: "Model",
            accessor: 'model'
        },
        {
            Header: "Series",
            accessor: 'series'
        },
        {
            Header: "Drive train",
            accessor: 'drivetrain'
        },
        {
            Header: "Cylinders",
            accessor: 'cylinders'
        },
        {
            Header: "Fuel",
            accessor: 'fuel'
        },
        {
            Header: "Transmission",
            accessor: 'transmission'
        },
        {
            Header: "Engine Displacement",
            accessor: 'displacement'
        },
        {
            Header: "Vehicle Type",
            accessor: 'vehicleType'
        },
        {
            Header: "State",
            accessor: 'state'
        },
        {
            Header: "Driving Condition",
            accessor: 'drivingConditionDesc'
        },
    ];


    useEffect(() => {
        retrieveIolmDetails();
    }, []);


    const retrieveIolmDetails = async () => {
        RecommendationService.getIolmIntervals()
            .then(response => {
                setData(response.data.vehicleDrivingConditionIOLMIntervalCountsResponses);
            })
            .catch(e => {
                console.log(e);
            });
    };


    return (

        <div className="iolm-component">
            <h2 className="fomo-type--title">Validate IOLM Configurations</h2>
            <CFAdminDataTable
                columns={columns}
                data={data}
                showAdd={false}
                textModal={'Add Make'}
            />
        </div>
    );
}

export default CFAdminIolmIntervals;