import http from "../util/http-common";
import {authHeader, authCodeHeader, authRefreshHeader} from "../util/auth-header";

export class AuthService {
  static getUrl() {
    return http.get("/cfadminsecurity/api/v1/auth/url");
  }

  static getUserDetails(){
    return http.get("/cfadminsecurity/api/v1/auth/loggedin",{ headers: authHeader()});
  }

  static getUserToken(code: any){
    return http.get("/cfadminsecurity/api/v1/auth/token",{ headers: authCodeHeader(code)});
  }

  static getRefreshToken(){
    return http.get("/cfadminsecurity/api/v1/auth/refresh",{ headers: authRefreshHeader()});
  }
}

export default AuthService;
