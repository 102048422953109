import { AccordionData } from './types';
import './AccordionItem.scss';
import { useEffect, useRef, useState } from 'react';

function AccordionItem({ data, isOpen, menuClose, btnOnClick }:
    { data: AccordionData, isOpen: boolean; menuClose: () => void; btnOnClick: () => void; }) {
    const contentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (isOpen) {
        const contentEl = contentRef.current as HTMLDivElement;

        setHeight(contentEl.scrollHeight);
        } else {
        setHeight(0);
        }
    }, [isOpen]);

    return (
        <li className={`accordion-item ${isOpen ? 'active' : ''}`}>
        <h2 className="accordion-item-title">
            <button className="accordion-item-btn" onClick={btnOnClick}>
            {data.img} &nbsp; {data.title}
            </button>
        </h2>
        <div className="accordion-item-container" style={{ height }}>
            <div ref={contentRef} className="accordion-item-content" onClick={menuClose}>
            {data.content}
            </div>
        </div>
        </li>
    );
}
      
export default AccordionItem;