import React from 'react';
import { ReactComponent as FordLoader } from '../../assets/images/ford-loader.svg';

const PleaseWait: React.FC = () => {
    return (
        <div id="please-wait"
             style={{
                 display: 'none',
                 position: 'fixed',
                 top: 0,
                 left: 0,
                 width: '100%',
                 height: '100%',
                 backgroundColor: 'rgba(0, 0, 0, 0.5)',
                 zIndex: 1000
             }}>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px'
            }}>
                <FordLoader height="50"/>
                <div>Please wait ...</div>
            </div>
        </div>
    );
};

export default PleaseWait;