import React, {useEffect, useRef, useState} from 'react';
import {Formik} from "formik";
import {FVPSelect} from "../../components/formField";
import * as Yup from "yup";
import VehicleConfigService from "../../services/VehicleConfigService";
import RecommendationService from "../../services/RecommendationService";
import './CFAdminCopyMaintenance.scss'
import CFAdminNotification from "../../components/CFAdminNotification";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import {VehicleAttributeType} from "../CFAdminVehicleConfig/types";
import CFAdminVehicleConfigDetails from "./CFAdminVehicleConfigDetails";
import CFAdminModal from "../../components/CFAdminModal";
import useVehicleState from '../../hooks/useVehicleState';
import {handleResponseCloneAndCopyVehicle} from "../../util/handleResponseCloneAndCopyVehicle";
import {getToYearModels, getVehicleYears, getYearModels} from "../../util/vehicleServiceUtils";

const schema = Yup.object().shape({
    fromModel: Yup.string(),
    fromYear: Yup.string(),
    toModel: Yup.string(),
    toYear: Yup.string(),
});

function CFAdminCopyMaintenance() {

    const childCompRef = useRef<any>();
    const [cloneVehicle, setCloneVehicle] = useState<any>({
        fromModel: '',
        fromYear: '',
        toModel: '',
        toYear: '',
        fromVehicleId: '',
        toVehicleId: '',
    });

    const {
        yearsOptions, setYearsOptions,
        toYearsOptions, setToYearsOptions,
        fromModelsOptions, setFromModelsOptions,
        toModelsOptions, setToModelsOptions,
        fromModelSelectValue, setFromModelSelectValue,
        toModelSelectValue, setToModelSelectValue,
        fromYearSelectValue, setFromYearSelectValue,
        toYearSelectValue, setToYearSelectValue,
        isFromYearSelected, setIsFromYearSelected,
        isFromModelSelected, setIsFromModelSelected,
        isToYearSelected, setIsToYearSelected,
        modelYearResponsesList, setModelYearResponsesList,
        isConfirmOpen, setIsConfirmOpen,
        confirmDescription, setConfirmDescription,
        fromColumnValues, setFromColumnValues,
        fromDataValues, setFromDataValues,
        fromTotalRecords, setFromTotalRecords,
        toColumnValues, setToColumnValues,
        toDataValues, setToDataValues,
        toTotalRecords, setToTotalRecords
    } = useVehicleState();

    const [isToOpen, setIsToOpen] = useState(false);
    const [isFromOpen, setIsFromOpen] = useState(false);
    const [enableFromSelectVehicleButton, setEnableFromSelectVehicleButton] = useState(false);
    const [isToModelSelected, setIsToModelSelected] = useState(false);
    const [enableToSelectVehicleButton, setEnableToSelectVehicleButton] = useState(false);
    const [countIsFromModelSelected, setCountIsFromModelSelected] = useState(0);
    const [countIsToModelSelected, setCountIsToModelSelected] = useState(0);


    const handleSubmit = async () => {

        if(cloneVehicle.fromVehicleId === cloneVehicle.toVehicleId){
            confirmErrorMessage("Source and Destination should not be the same.");
            return;
        }
        await setDescription();
        setIsConfirmOpen(true);
    };

    const getOptionDescriptions = (fromYear: string, toYear: string, fromModel: string, toModel: string) => {
        const fromYearOption = yearsOptions.find(option => String(option.code) === fromYear);
        const toYearOption = toYearsOptions.find(option => String(option.code) === toYear);
        const fromModelOption = fromModelsOptions.find(option => String(option.code) === fromModel);
        const toModelOption = toModelsOptions.find(option => String(option.code) === toModel);
        const fromMake = fromModelOption?.make;

        return {
            fromDescription: `${fromMake} ${fromYearOption?.description || ''} ${fromModelOption?.description || ''}`,
            toDescription: `${fromMake} ${toYearOption?.description || ''} ${toModelOption?.description || ''}`
        };
    };

    const setDescription = async () => {
        const { fromDescription, toDescription } = getOptionDescriptions(
            cloneVehicle.fromYear,
            cloneVehicle.toYear,
            cloneVehicle.fromModel,
            cloneVehicle.toModel
        );
        setConfirmDescription(`from ${fromDescription} to ${toDescription}`);
    };

    function getDescriptions(isFromSet: boolean): string {
        const { fromDescription, toDescription } = getOptionDescriptions(
            fromYearSelectValue,
            toYearSelectValue,
            fromModelSelectValue,
            toModelSelectValue
        );
        return isFromSet ? fromDescription : toDescription;
    }

    const cloneVehiclesOperation = () => {
        const request = {
            "fromVehicleId": cloneVehicle.fromVehicleId,
            "toVehicleId": cloneVehicle.toVehicleId
        };
        RecommendationService.copyVehicles(request)
            .then(response => {
                handleResponseCloneAndCopyVehicle(
                    response,
                    setIsConfirmOpen,
                    confirmSuccessMessage,
                    confirmErrorMessage,
                    "Vehicle configurations and maintenance recommendations copied " + confirmDescription,
                    "Error while Copying Vehicles " + confirmDescription
                );

                onSubmitCopyMaintenace();
            })
            .catch(e => {
                console.log(e);
                setIsConfirmOpen(false);
                confirmErrorMessage("Error while Copying Vehicles " +confirmDescription);
            });
    }

    useEffect(() => {
        getVehicleYears(setYearsOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };

    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

   const resetCloneVehicleState = () => {
    setFromModelsOptions([]);
    setToModelsOptions([]);
    setToYearsOptions([]);
    setFromModelSelectValue('');
    setToModelSelectValue('');
    setToYearSelectValue('');
    setIsFromYearSelected(false);
    setIsFromModelSelected(false);
    setIsToYearSelected(false);
    setCloneVehicle({
        ...cloneVehicle,
        toModel: '',
        toVehicleId: '',
        fromModel: '',
        fromVehicleId: ''
    });
    setEnableToSelectVehicleButton(false);
    setEnableFromSelectVehicleButton(false);
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onChangeFromYear = async (e: any) => {
    cloneVehicle.fromYear = e.target.value;
    setFromYearSelectValue(e.target.value);
    setFromModelsOptions([]);
    setToModelsOptions([]);
    setToYearsOptions([]);
    getYearModels(
        cloneVehicle.fromYear,
        yearsOptions,
        setIsFromYearSelected,
        setFromModelsOptions,
        setToYearsOptions,
        setModelYearResponsesList
    );
    resetCloneVehicleState();
};

const onSubmitCopyMaintenace = async () => {
    setFromYearSelectValue('Select');
    resetCloneVehicleState();
};
    const onChangeToYear = async (e: any) => {
        cloneVehicle.toYear = e.target.value;
        setToYearSelectValue(e.target.value);
        setToModelsOptions([]);

        setCloneVehicle({
            ...cloneVehicle,
            toModel: '',
            toVehicleId: ''
        });
        setToModelSelectValue('');
        await getToYearModels(
            cloneVehicle.toYear,
            modelYearResponsesList,
            setIsToYearSelected,
            setToModelsOptions
        );
        setEnableToSelectVehicleButton(false);
    };

    const onChangeFromModel = async (e: any) => {
        setIsFromModelSelected(false);
        cloneVehicle.fromModel = e.target.value;
        setFromModelSelectValue(e.target.value);
        await getVehicleConfigs(cloneVehicle.fromModel, true, () => {
            setCountIsFromModelSelected(countIsFromModelSelected+1);
            setIsFromModelSelected(true);
        });
        setToModelSelectValue('');
        setToYearSelectValue('');
        setEnableToSelectVehicleButton(false);
        setIsToYearSelected(false);
        cloneVehicle.toVehicleId = '';
        cloneVehicle.fromVehicleId = '';
    };

    const onSelectFromModel = async () => {
        setIsFromOpen(true);
    };

    const onSelectToModel = async () => {
        setIsToOpen(true);
    };

    const onChangeToModel = async (e: any) => {
        cloneVehicle.toModel = e.target.value;
        setToModelSelectValue(e.target.value);
        await getVehicleConfigs(cloneVehicle.toModel, false, () => {
            setIsToModelSelected(true);
            setCountIsToModelSelected(countIsToModelSelected+1);
        });
        cloneVehicle.toVehicleId = '';
    };

    const handleFromSelectVehicle = async (vehicleId: any) => {

        cloneVehicle.fromVehicleId = vehicleId;
        setIsFromOpen(false);
    };

    const handleToSelectVehicle = async (vehicleId: any) => {

        cloneVehicle.toVehicleId = vehicleId;
        setIsToOpen(false);
    };

    const handleVehicleClick = async (props: any, isFrom: boolean) => {

        if(isFrom){
            handleFromSelectVehicle(props.row.original.vehicleId)
        }else{
            handleToSelectVehicle(props.row.original.vehicleId)
        }
    };

    const getVehicleConfigs = async (modelYearId: any, isFrom: boolean, callback?: () => void) => {
        VehicleConfigService.getVehicleConfigurationsWithId(modelYearId)
            .then(response => {
                const responseModel = response.data?.vehicles;
                if (responseModel) {
                    let columns : any = [];
                    let data : any = [];
                    let count: number = 0;
                    let isColAdded : boolean = false;
                    responseModel.forEach((parentElement: any) => {
                        count = count+1;
                        if(!isColAdded) {
                            columns.push({Header: '', accessor:'id', disableSortBy: true});
                            parentElement.vehicleAttributes.forEach((childElement: VehicleAttributeType) => {
                                columns.push({ //Header: childElement.attributeDescription ,
                                    Header: () => (
                                        <div className="flex">
                                            <div className="inline-flex">{childElement.attributeDescription}
                                            </div>
                                        </div>
                                    ),
                                    disableSortBy: true,
                                    accessor: 'attributeValueDescription'+childElement.attributeId})
                            });
                            columns.push({
                                Header: "Actions",
                                Cell: (props: any) => {
                                    return (
                                        <div>
                                            <button data-testid={`vc-step-next${props.row.index}`}
                                                    type="button"
                                                    onClick={() => handleVehicleClick(props, isFrom)}>
                                                Select
                                            </button>
                                        </div>
                                    )
                                },
                            });
                            isColAdded = true;
                        }
                        data.push(createJson(count, parentElement.vehicleId, parentElement.vehicleAttributes));
                    });
                    if(isFrom){
                        setFromColumnValues(columns);
                        setFromDataValues(data);
                        setFromTotalRecords(count);
                    }else{
                        setToColumnValues(columns);
                        setToDataValues(data);
                        setToTotalRecords(count);
                    }

                    if (callback) {
                        callback();
                    }
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    function createJson(count:number, vehicleId: number, childElements: any)
    {
        let jsonData : any = {};
        childElements.forEach((childElement: VehicleAttributeType) => {
            let columnName = 'attributeValueDescription'+childElement.attributeId;
            jsonData[columnName] = childElement.attributeValueDescription;
        });
        jsonData['id'] = 'Configuration ' + count;
        jsonData['vehicleId'] = vehicleId;

        return jsonData;
    }

    const toggleFromModal = () => {
        setIsFromOpen(!isFromOpen);
    }

    const toggleToModal = () => {
        setIsToOpen(!isFromOpen);
    }

    useEffect(() => {

        if(countIsFromModelSelected && isFromModelSelected ) {

            if (fromTotalRecords > 0 ) {
                setIsFromOpen(true);
                setIsFromModelSelected(true);
                setEnableFromSelectVehicleButton(true);
            } else {
                if (fromTotalRecords === 0) {
                    confirmErrorMessage("No vehicle configurations found for the selected vehicle.");

                    setIsFromOpen(false);
                    setIsFromModelSelected(false);
                    setEnableFromSelectVehicleButton(false);
                }
            }
        } else {
            setIsFromModelSelected(false);
        }

    }, [fromTotalRecords, countIsFromModelSelected,isFromModelSelected,setIsFromModelSelected]);

    useEffect(() => {
        if( isToModelSelected && countIsToModelSelected ) {

            if (toTotalRecords > 0) {
                setIsToOpen(true);
                setIsToModelSelected(true);
                setEnableToSelectVehicleButton(true);
            } else {
                if (toTotalRecords === 0)
                    confirmErrorMessage("No vehicle configurations found for the selected vehicle.");

                setIsToOpen(false);
                setIsToModelSelected(false);
                setEnableToSelectVehicleButton(false);
            }
        }
    }, [toTotalRecords, countIsToModelSelected,isToModelSelected]);

    return (


        <>
            <div className="make-component">
                <h2 className="fomo-type--title">Copy Maintenance Recommendations by Vehicle/ConfigId</h2>
                <Formik
                    validationSchema={schema}
                    initialValues={{fromModel: '', fromYear: ''}}
                    onSubmit={handleSubmit}
                >
                    {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty}) => (
                        <div className="fvp-form">
                            <div className="modal-top-div">
                                <form noValidate onSubmit={handleSubmit}>
                                    <div className="modal-body">
                                        <div className="fvp-form__group">
                                            <div className="active-step">
                                                <>
                                                    <div className="ext1">
                                                        <div className="ext1-1">
                                                            * All recommendations will be copied from the source vehicle configuration to the destination vehicle configuration<br/>
                                                            * Any recommendations originally applied to the destination vehicle configuration will be removed. This information is not
                                                            recoverable.
                                                        </div>
                                                        <>
                                                            <div className="container">
                                                                <div className="box">
                                                                    <div className="box-row">
                                                                        <div className="box-cell box1">
                                                                            <div className="rcorners4">
                                                                                <center>
                                                                                    <b>From/Source</b>
                                                                                </center>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        label="Year:"
                                                                                        name="fromYear"
                                                                                        value={fromYearSelectValue}
                                                                                        data-testid="fromyear"
                                                                                        onChange={onChangeFromYear}
                                                                                        options={yearsOptions}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        label="Model:"
                                                                                        name="fromModel"
                                                                                        data-testid="frommodel"
                                                                                        value={fromModelSelectValue}
                                                                                        onChange={onChangeFromModel}
                                                                                        options={fromModelsOptions}
                                                                                        disabled={!isFromYearSelected}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="right-align">
                                                                                    <button data-testid="submit-select-from-next"
                                                                                            className="fvp-button button-right-align"
                                                                                            type="button"
                                                                                            onClick={() => onSelectFromModel()}
                                                                                            disabled={!enableFromSelectVehicleButton}>
                                                                                        Select Vehicle
                                                                                    </button>
                                                                                </div>
                                                                                <div className="fvp-form__subgroup" data-testid="from-selected-config">
                                                                                    Selected Config
                                                                                    : {cloneVehicle.fromVehicleId}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="box-cell box1">
                                                                            <div className="fvp-form__subgroup">
                                                                                &nbsp;&nbsp;&nbsp;
                                                                            </div>
                                                                            <div className="fvp-form__subgroup">
                                                                                &nbsp;&nbsp;&nbsp;
                                                                            </div>
                                                                            <div className="fvp-form__subgroup">
                                                                                <center>
                                                                                    <b className="box-cell box3">Copy</b>
                                                                                </center>

                                                                            </div>
                                                                        </div>
                                                                        <div className="box-cell box1">
                                                                            <div className="rcorners4">
                                                                                <center>
                                                                                    <b>To/Destination</b>
                                                                                </center>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        label="Year:"
                                                                                        name="toYear"
                                                                                        value={toYearSelectValue}
                                                                                        data-testid="toyear"
                                                                                        onChange={onChangeToYear}
                                                                                        options={toYearsOptions}
                                                                                        disabled={!cloneVehicle.fromVehicleId}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        label="Model:"
                                                                                        name="toModel"
                                                                                        data-testid="tomodel"
                                                                                        value={toModelSelectValue}
                                                                                        onChange={onChangeToModel}
                                                                                        options={toModelsOptions}
                                                                                        disabled={!isToYearSelected}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="right-align">
                                                                                    <button data-testid="submit-select-to-next"
                                                                                            className="fvp-button button-right-align"
                                                                                            type="button"
                                                                                            onClick={() => onSelectToModel()}
                                                                                            disabled={!enableToSelectVehicleButton}>
                                                                                        Select Vehicle
                                                                                    </button>
                                                                                </div>
                                                                                <div className="fvp-form__subgroup">
                                                                                    Selected Config
                                                                                    : {cloneVehicle.toVehicleId}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                </>
                                                <div className="right-align">
                                                    <button data-testid="submit-next"
                                                            className="fvp-button button-right-align" type="button"
                                                            onClick={() => handleSubmit()}
                                                            disabled={!cloneVehicle.toVehicleId || !cloneVehicle.fromVehicleId}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
            <CFAdminModal
                isOpen={isFromOpen}
                toggle={toggleFromModal}
                isAdd={true}
                title={'From Vehicle Configs'}
            >
                <CFAdminVehicleConfigDetails
                    columnValues={fromColumnValues}
                    dataValues={fromDataValues}
                    totalRecords={fromTotalRecords}
                    desc={getDescriptions(true)}/>
            </CFAdminModal>
            <CFAdminModal
                isOpen={isToOpen}
                toggle={toggleToModal}
                isAdd={true}
                title={'To Vehicle Configs'}
            >
                <CFAdminVehicleConfigDetails
                    columnValues={toColumnValues}
                    dataValues={toDataValues}
                    totalRecords={toTotalRecords}
                    desc={getDescriptions(false)}/>
            </CFAdminModal>

            <CFAdminNotification ref={childCompRef}/>
            <CFAdminConfirmDialog
                isOpen={isConfirmOpen}
                onClose={()=>setIsConfirmOpen(false)}
                onConfirm={cloneVehiclesOperation}
                title={"Vehicle configurations and maintenance recommendations will be copied " +confirmDescription}
                text="Are you sure you want to clone?"
            />
        </>
    );
}

export default CFAdminCopyMaintenance;