import React, {useEffect, useState} from "react";
import CFAdminDataTable from "../../components/CFAdminDataTable";
import SysMaintainenceService from "../../services/SysMaintainenceService";

function CFAdminCountries() {

    const [data, setData] = useState([]);
    const columns =  [
        {
            Header : "Id",
            accessor : 'id'
        },
        {
            Header : "Description",
            accessor : 'admin'
        },
        {
            Header : "ISO Code",
            accessor : 'isoCode'
        },
        {
            Header : "Last Modified By",
            accessor : 'userKey'
        },
        {
            Header : "Last Modified Date",
            accessor : 'modified'
        },
    ];

    useEffect(() => {
        retrieveCountries();
    }, []);



    const retrieveCountries = async () => {
        SysMaintainenceService.getAllCountries()
            .then(response => {
                setData(response.data.countriesResponseModel);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <>
          <h2 className="fomo-type--title">Manage Countries</h2>
            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={''}
                textModal={'none'}
            />
        </>
      );
  }
  
  export default CFAdminCountries;