import './CFAdminHeader.scss';
import FordServiceImage from '../../assets/images/ford-service.png';
import MercuryImage from '../../assets/images/Mercury-Emblem.png';
import FordImage from '../../assets/images/Ford_Oval.svg';
import LincolnImage from '../../assets/images/Lincoln-logo.png';
import CFAdminNavigation from '../CFAdminNavigation';
import { AppProps } from '../CFAdminNavigation/types';


function CFAdminHeader(user : AppProps) {
  return (
      <header className="header">
      { user.authorised ? 
       <nav>
        <CFAdminNavigation {...user} />
       </nav> : ''
       }
       <div className='header-container'>
          <div className='header-title'>
             <h1 className='text'>Maintenance Schedule Pricing</h1>    
          </div>   
          <div className='header-image'>
          <img src={FordImage} alt="Xcelvations Logo" height="40" />
          <img src={LincolnImage} alt="Xcelvations Logo" height="40" />
          <img src={MercuryImage} alt="Xcelvations Logo" height="40" />
          <img src={FordServiceImage} alt="Xcelvations Logo" height="40" />
          </div>
       </div>
      </header>
  );
}


export default CFAdminHeader;